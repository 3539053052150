import React, { useState, useEffect } from 'react';
//import { handleAppears } from "../../index.jsx";

import './Page404.css'

import { listTeam } from "../../data/listTeam.jsx";


export default function Page404() {

  return (
    <main className="main404">
      This page is dead.
    </main>
  )
}
import React, { useEffect, useState } from "react";
import { NavLink } from 'react-router-dom'

import "./Header.css";
import logo from "./assets/blockchainHEC.svg";

import youtubeLogo from "./assets/icon_youtube.svg";
import linkedinLogo from "./assets/icon_linkedin.svg";
import mailLogo from "./assets/icon_mail.svg";
import discordLogo from "./assets/icon_discord.svg";
import instaLogo from "./assets/icon_instagram.svg";
import spotifyLogo from "./assets/icon_spotify.svg";

export default function Header() {
  // const [width, setWidth] = useState(window.innerWidth);
  let openCloseBuger;
  let elementBurger;
  let elementPlein;

  function toggleMenu() {
    console.log("toggle");
    openCloseBuger = !openCloseBuger;
    elementBurger.classList.toggle("OCburger");
    elementPlein.classList.toggle("hide");
  }

  function closeMenu() {
    console.log("close");
    openCloseBuger = !openCloseBuger;
    elementBurger.classList.remove("OCburger");
    elementPlein.classList.remove("hide");
  }

  useEffect(() => {
    elementBurger = document.querySelector("#burger");
    elementPlein = document.querySelector("nav");
  }, []);

  document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
    anchor.addEventListener("click", function(e) {
      e.preventDefault();

      document.querySelector(this.getAttribute("href")).scrollIntoView({
        behavior: "smooth",
      });
    });
  });

  return (
    <main>
      <header className="mainDiv">
        <div>
          <NavLink to="/" onClick={closeMenu}>
            <img src={logo} className="logoBH" alt="logo blockchain hec" />
          </NavLink>
        </div>
        <div className="rightHeader">
          <nav>
            <NavLink to="/team">
              <div className="buttonHeader" onClick={closeMenu}>About us</div>
            </NavLink>
            <NavLink to="/events">
              <div className="buttonHeader" onClick={closeMenu}>Events</div>
            </NavLink>
            <NavLink to="/organisation">
              <div className="buttonHeader" onClick={closeMenu}>Community</div>
            </NavLink>
            <NavLink to="/incubator">
              <div className="buttonHeader" onClick={closeMenu}>Blockchain Labs</div>
            </NavLink>
            <div className="divLogos header">
              <a href="https://discord.gg/t5fVuuftbw" target="_blank">
                <img src={discordLogo} alt="Icon svg Youtube" />
              </a>
              <a href="https://www.instagram.com/blockchainhec/" target="_blank">
                <img src={instaLogo} alt="Icon svg Youtube" />
              </a>
              <a href="https://www.youtube.com/channel/UCnUuMfRKsp0f7MoTySDyINg?sub_confirmation=1" target="_blank">
                <img src={youtubeLogo} alt="Icon svg Youtube" />
              </a>
              <a href="https://www.linkedin.com/company/blockchain-hec/" target="_blank">
                <img src={linkedinLogo} alt="Icon svg LinkedIn" />
              </a>
              <a href="mailto:blockchainhec@gmail.com">
                <img src={mailLogo} alt="Icon svg mail" />
              </a>
              <a href="https://open.spotify.com/show/1CEuyX5XpmhkC8cQhF6RbL?si=0862c8f600f544be" target="_blank">
                <img src={spotifyLogo} alt="Icon svg spotify" />
              </a>
            </div>
          </nav>
          <div id="burger" onClick={toggleMenu}>
            <div className="up" />
            <div className="middle" />
            <div className="down" />
          </div>
        </div>
      </header>
    </main>
  );
}

import React, { useState, useEffect } from "react";
import "./PastSpeakers.css";

import { listSpeakers } from "../../data/listSpeakers.jsx";
import bouteloup from "../../assets/bouteloup.png";

export default function PastSpeakers() {
  const [dataTeam, setDataTeam] = useState();

  useEffect(() => {
    fetch(
      "https://opensheet.elk.sh/1nbcwNXNcgQlv1MTB8yKRrdWMzDdNYIbNkvvPyxXwqP4/Speakers"
    )
      .then((res) => res.json())
      .then((rows) => {
        setDataTeam(rows);
      });
    console.log("dataTeam");
    console.log(dataTeam);
  }, []);

  return (
    <div className="mainPastSpeakers toAppear">
      <h3>Past Speakers</h3>
      <div className="carrouselPastSpeakers">
        {dataTeam ? (
          dataTeam.map(({ name, picture, linkedin, titre }, index) => (
            <div key={index}>
              <img src={picture}></img>
              <div className="titleSpeakers">
                <a href={linkedin} target="_blank">
                  <div className="NameSpeakers">{name}</div>
                  <div className="NameSpeakers">{titre}</div>
                </a>
              </div>
            </div>
          ))
        ) : (
          <div className="carrouselPastSpeakers">Loading...</div>
        )}
      </div>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import { handleAppears } from "../../index.jsx";

import "./Team.css";

import { listTeam } from "../../data/listTeam.jsx";

export default function Team() {
  const [dataTeam, setDataTeam] = useState();

  useEffect(() => {
    fetch(
      "https://opensheet.elk.sh/1nbcwNXNcgQlv1MTB8yKRrdWMzDdNYIbNkvvPyxXwqP4/Team"
    )
      .then((res) => res.json())
      .then((rows) => {
        setDataTeam(rows);
      });
    console.log("dataTeam");
    console.log(dataTeam);
    // console.log(listTeam);
    //setPastDataEvents(listEvents.filter((pres) => new Date() > new Date(pres.dateEtHeure)));

    handleAppears();
  }, []);

  // setInterval(function () {
  //   console.log(dataTeam);
  // }, 2000);

  return (
    <main className="mainTeam">
      <div className="titlePage">
        <h2 className="radiantBluePurple">Team</h2>
      </div>
      <div className="divTeam toAppear">
        {dataTeam ? (
          dataTeam.map(
            ({ name, picture, role, classe, linkedin, current }, index) => {
              return (
                <a href={linkedin} target="_blank">
                  <div className="cardTeam" key={index}>
                    <img
                      src={require(`../../${picture}`)}
                      alt={"Picture of " + name}
                    />
                    <div className="descTeam">
                      <div className="roleTeam">{role}</div>
                      <div className="nameTeam">{name}</div>
                      <div className="classTeam">{classe}</div>
                    </div>
                    {classe ? (
                      <div
                        className="bulleTeam"
                        style={{
                          backgroundColor: current ? "#3E36E6" : "white",
                        }}
                        title={current ? "Current student" : "Former student"}
                      />
                    ) : null}
                  </div>
                </a>
              );
            }
          )
        ) : (
          <div className="waitBox" />
        )}
      </div>
    </main>
  );
}

import "./Footer.css";
import youtubeLogo from "./assets/icon_youtube.svg";
import linkedinLogo from "./assets/icon_linkedin.svg";
import mailLogo from "./assets/icon_mail.svg";
import discordLogo from "./assets/icon_discord.svg";
import instaLogo from "./assets/icon_instagram.svg";
import spotifyLogo from "./assets/icon_spotify.svg";

export default function Footer() {
  return (
    <div className="mainFooter">
      <div className="leftFooter">
        <a href="">Mention légales</a>
        <a href="https://drive.google.com/drive/folders/1aKView5lZQ4zgbYovPyGndTurbSOSaFw?usp=sharing" target="Blank">Support marketing</a>
        <div>Tous droits résersés</div>
        <div>Blockchain@HEC</div>
      </div>
      <div className="rightFooter">
        <div className="divLogos footer">
          <a href="https://discord.gg/t5fVuuftbw" target="_blank">
            <img src={discordLogo} alt="Icon svg Youtube" />
          </a>
          <a href="https://www.instagram.com/blockchainhec/" target="_blank">
            <img src={instaLogo} alt="Icon svg Youtube" />
          </a>
          <a href="https://www.youtube.com/channel/UCnUuMfRKsp0f7MoTySDyINg?sub_confirmation=1" target="_blank">
            <img src={youtubeLogo} alt="Icon svg Youtube" />
          </a>
          <a href="https://www.linkedin.com/company/blockchain-hec/" target="_blank">
            <img src={linkedinLogo} alt="Icon svg LinkedIn" />
          </a>
          <a href="mailto:blockchainhec@gmail.com">
            <img src={mailLogo} alt="Icon svg mail" />
          </a>
          <a href="https://open.spotify.com/show/1CEuyX5XpmhkC8cQhF6RbL?si=0862c8f600f544be" target="_blank">
            <img src={spotifyLogo} alt="Icon svg spotify" />
          </a>
        </div>
        <div>
          Made with ❤️ by{" "}
          <a href="https://github.com/Benoitded" target="_blank">
            Benoît
          </a>
        </div>
      </div>
    </div>
  );
}

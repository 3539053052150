import React, { useState, useEffect } from 'react';
import {handleAppears} from "../../index.jsx";

import './Organisation.css'

import bhec_organisation from "../../assets/bhec_diagramme.png";


export default function Events() {
  
  useEffect(() => {
    handleAppears();
  }, []);
  
  return (
    <main className="mainTeam">
      <div className="titlePage">
        <h2 className="radiantBluePurple">Organisation</h2>
      </div>
      <div className="divOrganisation toAppear">
        <img src={bhec_organisation}/>
      </div>
      <div className="secondDivOrganisation toAppear">
        <div className="secondSubDivOrganisation">
          <div>
            We build blockchain ecosystems and share knowledge
          </div>
          <div className="divNumbersOrganisation">
            <div className="divNumberOrganisation">
              <div className="nbOrganisation">70,000+</div>
              <div className="descNbOrganisation">HEC Alumni</div>
            </div>
            <div className="divNumberOrganisation">
              <div className="nbOrganisation">5,000+</div>
              <div className="descNbOrganisation">Members</div>
            </div>
            <div className="divNumberOrganisation">
              <div className="nbOrganisation">5,000+</div>
              <div className="descNbOrganisation">HEC students</div>
            </div>
            <div className="divNumberOrganisation">
              <div className="nbOrganisation">100+</div>
              <div className="descNbOrganisation">Speakers</div>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}
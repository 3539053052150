import React from "react";
import "./Sponsors.css";
import { listSponsors } from "../../data/listSponsors.jsx";

import stakeLogo from "../../assets/sponsors/stake.jpg";
import pwcLogo from "../../assets/sponsors/pwc.jpg";
import taurusLogo from "../../assets/sponsors/taurus.jpg";
import dnaLogo from "../../assets/sponsors/dna.jpg";

export default function Sponsors() {
  return (
    <div className="blockSponsors toAppear">
      <div className="subDivSponsors">
        <h3>Sponsors</h3>
        <div className="divPrime">
          {listSponsors
            .filter((e) => e.type.includes("prime"))
            .map((filteredPerson) => {
              let imgSrc = filteredPerson.picture.default;
              return (
                <a
                  href={filteredPerson.link}
                  target="_blank"
                  key={filteredPerson.name}
                >
                  <img
                    src={filteredPerson.picture}
                    alt={"Logo of " + filteredPerson.name}
                    title={"Logo of " + filteredPerson.name}
                    className="prime_img"
                  />
                </a>
              );
            })}
        </div>
      </div>
      <div className="subDivSponsors">
        <h3>Members</h3>
        <div className="carousel">
          <div className="carousel_items" id="test">
            {listSponsors
              .filter((e) => e.type.includes("sponsors"))
              .map((filteredPerson) => (
                <a
                  href={filteredPerson.link}
                  target="_blank"
                  key={filteredPerson.name}
                >
                  <img
                    src={filteredPerson.picture}
                    alt={"Logo of " + filteredPerson.name}
                    title={"Logo of " + filteredPerson.name}
                    className="carousel_img"
                  />
                </a>
              ))}
            {listSponsors
              .filter((e) => e.type.includes("sponsors"))
              .map((filteredPerson) => (
                <a
                  href={filteredPerson.link}
                  target="_blank"
                  key={filteredPerson.name}
                >
                  <img
                    src={filteredPerson.picture}
                    alt={"Logo of " + filteredPerson.name}
                    className="carousel_img"
                  />
                </a>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}

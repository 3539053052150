import React from 'react';

import './Incubator.css';

import construction from "../../assets/construction.svg";


export default function Incubator() {
  return (
    <main className="mainTeam">
      <div className="titlePage">
        <h2 className="radiantBluePurple">Incubator</h2>
      </div>
      <div className="divIncubator">
        <img className="construction" src={construction} alt="logo page construction gradient svg" title="This page is currently in construction, but it will be alwailable soon ..."/>
        <div class="stage">
          <div class="dot-pulse"></div>
        </div>
      </div>
    </main>
  )
}
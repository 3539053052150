import React, { useState, useEffect } from 'react';
import { handleAppears } from "../../index.jsx";

import './Events.css'

import PastSpeakers from "../Home/PastSpeakers.jsx";
import CarrousselEvents from "./CarrousselEvents.jsx";
import MosaiqueEvents from "./MosaiqueEvents.jsx";

import { listEvents } from "../../data/listEvents.jsx";

export default function Events() {
  const [dataEvents, setDataEvents] = useState();
  const [dataPastEvents, setPastDataEvents] = useState();
  const [dataFutureEvents, setFutureDataEvents] = useState();

  useEffect(() => {
    fetch("https://opensheet.elk.sh/1nbcwNXNcgQlv1MTB8yKRrdWMzDdNYIbNkvvPyxXwqP4/EventsNew")
      .then(res => res.json())
      .then(rows => {
        //console.log("rows");
        //console.log(rows);
        setDataEvents(rows);
        setPastDataEvents(rows.filter((pres) => new Date() > new Date(pres.dateEtHeure)));
        setFutureDataEvents(rows.filter((pres) => new Date() < new Date(pres.dateEtHeure)));
      })
    handleAppears();
  }, []);


  const [filter, setFilter] = useState("");
  const [pastFilter, setPastFilter] = useState("");

  let futureEvents = listEvents.filter(
    (pres) => new Date() < new Date(pres.dateEtHeure)
  );

  /*let pastEvents = listEvents.filter(
    (pres) => new Date() > new Date(pres.dateEtHeure)
  );//*/

  return (
    <main className="mainTeam">
      <div className="titlePage">
        <h2 className="radiantBluePurple">Events</h2>
      </div>
      <div className="divEvents toAppear">
        <div className="divHeaderEvents">
          <div className="divTitleAndCount">
            <h3>Next events</h3>
            <div className="divCountEvents">{dataFutureEvents ? dataFutureEvents.length : 0}</div>
          </div>
          <input type="text" id="filterEvent" placeholder="Search" onChange={(e) => setFilter(e.target.value)} />
        </div>
        <CarrousselEvents events={dataFutureEvents} filter={filter} />
      </div>
      <div className="divEvents toAppear">
        <div className="divHeaderEvents">
          <div className="divTitleAndCount">
            <h3>Past events</h3>
            <div className="divCountEvents">{dataPastEvents ? dataPastEvents.length : 0}</div>
          </div>
          <input type="text" id="filterPastEvent" placeholder="Search" onChange={(e) => setPastFilter(e.target.value)} />
        </div>
        <MosaiqueEvents events={dataPastEvents} filter={pastFilter} />
      </div>
    </main>
  )
}
export const listTeam = [
  {
    name: "David Servais",
    picture: "https://media-exp1.licdn.com/dms/image/C4E03AQGIfNh8F5D_dw/profile-displayphoto-shrink_800_800/0/1516343524587?e=1674086400&v=beta&t=jcQ3THOfWOC80RaMoRM3no8YbVXkj6gv9TglrWDZJuc",
    linkedin: "https://www.linkedin.com/in/davidservais/",
    role: "President & Founder",
    classe: "MBA 2010",
    current: false
  },
  {
    name: "Athan Xiaocong Guo",
    picture: "https://media-exp1.licdn.com/dms/image/C4E03AQFQ1Hl4o8zniA/profile-displayphoto-shrink_800_800/0/1663831732718?e=1674086400&v=beta&t=lqWBL1xLy3u7NqOHTUBRYC78LsRyDS4vTcixwAVcVAU",
    linkedin: "https://www.linkedin.com/in/athan-xiaocong-guo-350665174/",
    role: "President Student Club",
    classe: "M1 Grande Ecole",
    current: true
  },
  {
    name: "Maxime Hamonic",
    picture: "https://media-exp1.licdn.com/dms/image/C4D03AQHgSv36a0FzRA/profile-displayphoto-shrink_800_800/0/1648048840771?e=1674086400&v=beta&t=CYArtm9iyx8uWepW2ge0zsFDBaY_Oigb2gB71wdGHW0",
    linkedin: "https://www.linkedin.com/in/maximehamonic/",
    role: "VP Communications",
    classe: "MSc Complex Project Management 2017",
    current: false
  },
  {
    name: "Jérémie Zarka",
    picture: "https://media-exp1.licdn.com/dms/image/C5603AQEdN2YXiI8bPA/profile-displayphoto-shrink_800_800/0/1559957483888?e=1674086400&v=beta&t=XIywtNJYWmdXhxFj8v5q1WnjoTJk5Hu3clzyXmMXWn4",
    linkedin: "https://www.linkedin.com/in/jeremie-zarka/",
    role: "VP Events",
    classe: "MBA Entrepreneurship 2019",
    current: false
  },
  {
    name: "Benoît de Dieuleveult",
    picture: "https://media-exp1.licdn.com/dms/image/C4E03AQHjfLnq7ohQ3g/profile-displayphoto-shrink_800_800/0/1662676096844?e=1674086400&v=beta&t=tSC7GXQAJi1RzPEweci02Q-r82mbecpBdHfw85QCFRs",
    linkedin: "https://www.linkedin.com/in/benoit-de-dieuleveult/",
    role: "Community & Webmaster",
    classe: "MSc X-HEC Entrepreneurs",
    current: true
  },
  {
    name: "Nancy Kang",
    picture: "https://media-exp1.licdn.com/dms/image/D4E03AQHj5gFyUP-qsA/profile-displayphoto-shrink_800_800/0/1668544487872?e=1674086400&v=beta&t=p8La9430wfIpZu_F7vssnhIfxnKLCuTrveykKVFc4fM",
    linkedin: "https://www.linkedin.com/in/lanxik/",
    role: "VP Marketing",
    classe: "M1 Grande Ecole",
    current: true
  },
  {
    name: "Eric Olombel",
    picture: "https://media.licdn.com/dms/image/D4E03AQE7CL05Ar6OdA/profile-displayphoto-shrink_800_800/0/1671528703641?e=1677110400&v=beta&t=2t3sbh3HuEDw_0R6boOPqw520C4UNM35gygU7YFKggY",
    linkedin: "https://www.linkedin.com/in/eric-olombel/",
    role: "VP investors",
    classe: "Exec MSc Innovation",
    current: true
  },
  {
    name: "Dimitri Mouëllic",
    picture: "https://media-exp1.licdn.com/dms/image/C4E03AQE2I2R0jDsYiQ/profile-displayphoto-shrink_800_800/0/1662993012364?e=1674691200&v=beta&t=DERGBgjlHNYShoKTOhSyVi5_5NGAnY7N5F76zyExUtY",
    linkedin: "https://www.linkedin.com/in/dimitri-mou%C3%ABllic-3b9785154/",
    role: "Video processing",
    classe: "",
  }
]
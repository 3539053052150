import ReactDOM from 'react-dom/client'
import App from './App'
import React, { useState, useEffect } from 'react';


ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)

export function handleAppears() {
  let valueT = document.getElementsByClassName("toAppear");
  Array.from(valueT).map((e, k) => {
    let dist = e.getBoundingClientRect().top - window.innerHeight;
    if (dist < 0) {
      e.classList.add("appear")
    }
  })
}
export const listSponsors = [
  {
    name: "Stake Capital Group",
    picture: require("../assets/sponsors/stake.jpg"),
    link: "https://www.stake.capital/",
    type: "prime",
  },
  {
    name: "PwC",
    picture: require("../assets/sponsors/pwc.jpg"),
    link: "https://www.pwc.fr/",
    type: "prime",
  },
  {
    name: "Taurus",
    picture: require("../assets/sponsors/taurus.jpg"),
    link: "https://www.taurushq.com/",
    type: "prime",
  },
  {
    name: "D&A",
    picture: require("../assets/sponsors/dna.jpg"),
    link: "https://www.linkedin.com/company/d-and-a-partners/",
    type: "prime",
  },
  {
    name: "Paris Blockchain Week",
    picture: require("../assets/sponsors/pbw.png"),
    link: "https://www.parisblockchainweek.com/",
    type: "prime",
  },
  {
    name: "Tezos",
    picture: require("../assets/members/tezos.jpg"),
    link: "https://tezos.com/",
    type: "sponsors",
  },
  {
    name: "The Sandbox",
    picture: require("../assets/members/thesandbox.jpg"),
    link: "https://register.sandbox.game/",
    type: "sponsors",
  },
  {
    name: "Adan",
    picture: require("../assets/members/adan.jpg"),
    link: "https://adan.eu/",
    type: "sponsors",
  },
  {
    name: "Binance",
    picture: require("../assets/members/binance.jpg"),
    link: "https://www.binance.com/en/activity/referral-entry/CPA?fromActivityPage=true&ref=CPA_006QC53M4U",
    type: "sponsors",
  },
  {
    name: "Dfns",
    picture: require("../assets/members/dfns.png"),
    link: "https://www.dfns.co/",
    type: "sponsors",
  },
  {
    name: "RealToken",
    picture: require("../assets/members/realT.jpg"),
    link: "https://realt.co/",
    type: "sponsors",
  },
  {
    name: "Forge",
    picture: require("../assets/members/forge.jpeg"),
    link: "http://www.sgforge.com",
    type: "sponsors",
  },
  {
    name: "CoinHouse",
    picture: require("../assets/members/coinhouse.jpg"),
    link: "https://www.coinhouse.com/fr/",
    type: "sponsors",
  },
];

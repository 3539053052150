import React from 'react';

function NoPage() {
    return (
        <h1>No page
        <h1>No page</h1>
        <h1>No page</h1>
        <h1>No page</h1>
        <h1>No page</h1>
        <h1>No page</h1>
        </h1>
    );
}

export default NoPage;
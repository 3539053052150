export const listSpeakers = [{
  name: "Julien Bouteloup",
  picture: "https://avatars.githubusercontent.com/u/3680497?v=4",
  linkedin: "https://twitter.com/bneiluj",
  titre: "StakeDAO"
},{
  name: "Pauline Adam Kalfon",
  picture: "https://media-exp1.licdn.com/dms/image/C5603AQGiiNycmJdqow/profile-displayphoto-shrink_800_800/0/1625669192970?e=1674086400&v=beta&t=mshj7QC1l2irySH3fa22H0qSGqJ3rrEAQWE4zmXjh60",
  linkedin: "https://www.linkedin.com/in/paulineadamkalfon/",
  titre: "PwC"
},{
  name: "Sébastien BORGET",
  picture: "https://media-exp1.licdn.com/dms/image/C4E03AQFu9ShUWNMB_A/profile-displayphoto-shrink_800_800/0/1655768930122?e=1674086400&v=beta&t=0TiuaONuVxkLMyOFP_g8ud0q7czdrYoMFmG62IZEJwo",
  linkedin: "https://www.linkedin.com/in/borgetsebastien/?originalSubdomain=fr",
  titre: "The Sandbox"
},{
  name: "Faustine Fleuret",
  picture: "https://media-exp1.licdn.com/dms/image/C4E03AQEUOSeaHRiOBw/profile-displayphoto-shrink_800_800/0/1661179357637?e=1674086400&v=beta&t=BSmodzubtrp7_nwkIqdyYhmt0VhqHVgoRynnKDy2wYQ",
  linkedin: "https://www.linkedin.com/in/faustine-fleuret-640b67a4/",
  titre: "Adan"
},{
  name: "David Prinçay",
  picture: "https://media-exp1.licdn.com/dms/image/C5603AQH26yymNtHM4Q/profile-displayphoto-shrink_800_800/0/1646932169290?e=1674086400&v=beta&t=OQzvcdHIHgq78MyZt2204fu8sURTRmJLzwPsWKwTWKc",
  linkedin: "https://www.linkedin.com/in/david-princay",
  titre: "Binance"
},{
  name: "Clarisse Hagege",
  picture: "https://media-exp1.licdn.com/dms/image/C5603AQGqi48mvmmmzw/profile-displayphoto-shrink_800_800/0/1636026606655?e=1674086400&v=beta&t=eFIE5Qs67wl2KIBdDUOMMwWEqk-Jn_cG9n8V-DQGB3A",
  linkedin: "https://www.linkedin.com/in/clarissehagege/",
  titre: "Dfns"
},{
  name: "Jean-Marc Jacobson",
  picture: "https://media-exp1.licdn.com/dms/image/C4E03AQELWrkFwoZDrQ/profile-displayphoto-shrink_800_800/0/1652964173378?e=1674086400&v=beta&t=aKDmKhSdExAIS6z7vx66ifM4kpvkmGz1Ul3VW62W3kA",
  linkedin: "https://www.linkedin.com/in/jean-marc-jacobson-5b1578/",
  titre: "RealToken"
},{
  name: "Stephane Duzan",
  picture: "https://media-exp1.licdn.com/dms/image/D4E03AQFIveJC_si3Kg/profile-displayphoto-shrink_800_800/0/1663936160657?e=1674086400&v=beta&t=6GmrI3kuhsw4giUzhoEWF9r4x8h_atQJGMFN76LtE-I",
  linkedin: "https://www.linkedin.com/in/st%C3%A9phane-duzan-b684331/",
  titre: "Forge"
},{
  name: "Nicolas Louvet",
  picture: "https://media.licdn.com/dms/image/D4E03AQERQB6nCLJzwg/profile-displayphoto-shrink_800_800/0/1672648149811?e=1678320000&v=beta&t=-arZgU8l-gUz9REWrTrf_qk0aogqg4M5Qi6fxPPC-nY",
  linkedin: "https://www.linkedin.com/in/nicolas-louvet-25537/",
  titre: "CoinHouse"
},{
  name: "Jeremy de Trychey",
  picture: "https://media-exp1.licdn.com/dms/image/C4D03AQFyue13BVCIpw/profile-displayphoto-shrink_800_800/0/1543937451268?e=1674086400&v=beta&t=klkVlDlFSqTZmgYisLR5PNgbGBlnCaaDL2vVRI8iBus",
  linkedin: "https://www.linkedin.com/in/jerome-de-tychey/",
  titre: "Cometh"
},{
  name: "Paul Franbot",
  picture: "https://pbs.twimg.com/profile_images/1587809185680465922/x0wE_r5u_400x400.jpg",
  linkedin: "https://www.linkedin.com/in/paul-frambot/",
  titre: "Morpho"
},{
  name: "Thibaut Chesse",
  picture: "https://media-exp1.licdn.com/dms/image/C4E03AQFeKT8bZx21pQ/profile-displayphoto-shrink_800_800/0/1652291836676?e=1674086400&v=beta&t=YJW6XmdhPmWQYduJj91thN_MYLvqC4RX5s_tAwcZz2s",
  linkedin: "https://www.linkedin.com/in/thibautchesse/",
  titre: "Sparkle Ventures"
},{
  name: "David Schmitz",
  picture: "https://media-exp1.licdn.com/dms/image/C4E03AQH0r80TOMPVFQ/profile-displayphoto-shrink_800_800/0/1628628729331?e=1674086400&v=beta&t=lvKqO7GDkYETFc1eZizqPY1nluwNv3pDrGf9uKinfms",
  linkedin: "https://www.linkedin.com/in/davschmitz/",
  titre: "Logion"
}]
import "./MosaiqueEvents.css";

import { listSpeakers } from "../../data/listSpeakers.jsx";

import agenda from "../../assets/agenda.svg";
import userImg from "../../assets/user.svg";

const months = [
  "Jan.",
  "Fév.",
  "Mars",
  "Avr.",
  "Mai",
  "Juin",
  "Juil.",
  "Août",
  "Sept.",
  "Oct.",
  "Nov.",
  "Déc"
];
const days = [
  "Dimanche",
  "Lundi",
  "Mardi",
  "Mercr.",
  "Jeudi",
  "Vendr.",
  "Samedi"
];

export default function MosaiqueEvents({ events, filter }) {
  let eventFuture = 1;

  function timestampToString(timestamp) {
    const date = new Date(timestamp);
    let returnString = "";
    returnString =
      days[date.getDay()] +
      " " +
      date.getDate() +
      " " +
      months[date.getMonth()] +
      " " +
      date.getHours() +
      ":" +
      date.getMinutes().toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false
      });
    return returnString;
  }
  let filteredObject = [];
  if (events) {
    filteredObject = events.filter(item => item.titre.toLowerCase().includes(filter.toLowerCase()));
  }

  console.log("filteredObject mosaique");
  console.log(filteredObject);


  return (
    <div className="mosaiqueDiv">
      {filteredObject.length ?
        filteredObject.map(({
          dateEtHeure,
          description,
          image,
          lienVideo,
          titre,
          nomSpeaker,
          photoSpeaker
        }, index) => (
          <div className="mosaEvent" title={description} key={index}>
            <div style={{ marginBottom: "16px" }}>
              <img
                src={image}
                alt={"miniature evenement blockchain hec" + titre}
                className="imageMiniature"
              />
            </div>
            <div className="mosaInfoDiv">
              <div style={{ fontSize: "32", fontWeight: "600" }}>
                {titre}
              </div>
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div>
                    <img
                      src={agenda}
                      style={{ width: "20px", marginRight: "6px" }}
                    />
                  </div>
                  <div>{timestampToString(dateEtHeure)}</div>
                </div>
                <a href={"https://www.linkedin.com/search/results/all/?keywords=" + nomSpeaker} target="_blank" >
                  <img src={photoSpeaker ? photoSpeaker : userImg} alt="Speaker" style={{ width: "32px", borderRadius: "16px" }} title={nomSpeaker} />
                </a>
              </div>
              <a
                href={lienVideo}
                target="_blank"
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <button className="btnEvent">Replay</button>
              </a>
            </div>
          </div>
        )
        )
        : <div className="noEvents">No events with this filter, try another one</div>
      }
    </div>
  );
}

import React, { useState, useEffect } from 'react';
import '../../App.css'
import Headline from "./Headline.jsx";
import NextEvent from "./NextEvent.jsx";
import PastSpeakers from "./PastSpeakers.jsx";
import Sponsors from "./Sponsors.jsx";
import { handleAppears } from "../../index.jsx"

export default function App() {

  useEffect(() => {
    handleAppears();
  }, [])

  return (
    <main>
      <Headline />
      <NextEvent />
      <PastSpeakers id="speakerDiv" />
      <Sponsors />
    </main>
  )
}

import "./Headline.css";
import youtubeLogo from "../../assets/icon_youtube.svg";
import linkedinLogo from "../../assets/icon_linkedin.svg";

export default function Headline() {
  return (
    <main>
      <div className="mainHeadline toAppear">
        <h1 className="radiantBluePurple">Web3</h1>
        <div className="descHeadline">
          <div className="columnHeadline">
            <h2>Education</h2>
            <h2>Community</h2>
            <h2>Blockchain Labs</h2>
          </div>
          <div className="columnHeadline">
            <h2>Increase Your <span className="radiantBluePurple">Knowledge</span></h2>
            <h2>Connect with <span className="radiantBluePurple">Talents</span></h2>
            <h2>Unleash <span className="radiantBluePurple">Opportunities</span></h2>
          </div>
        </div>
      </div>
    </main>
  );
}

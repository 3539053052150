import "./NextEvent.css";
import React, { useState, useEffect } from 'react';
import { listEvents } from "../../data/listEvents.jsx";

export default function NextEvent() {

  const [dataNextEvent, setDataNextEvent] = useState([{
    titre: "Next event coming soon, stay tuned!",
    dateEtHeure: ""
  }]);

  useEffect(() => {
    fetch("https://opensheet.elk.sh/1nbcwNXNcgQlv1MTB8yKRrdWMzDdNYIbNkvvPyxXwqP4/EventsNew")
      .then(res => res.json())
      .then(rows => {
        console.log("rows");
        console.log(rows);
        let memEvent = rows.filter(
          (pres) => new Date() < new Date(pres.dateEtHeure)
        );
        console.log("memEvent");
        console.log(memEvent);
        if (memEvent[0]) {
          console.log("update");
          setDataNextEvent(memEvent[dataNextEvent.length - 1]);
        }
        else {
          setDataNextEvent(dataNextEvent[0])
          //setDataNextEvent(memEvent[0]);
        }
        //setDataNextEvent(rows.filter((pres) => new Date() > new Date(pres.dateEtHeure)));
      })
  }, []);

  let prochainEvent = [{
    titre: "Next event coming soon, stay tuned!",
    dateEtHeure: ""
  }];
  /*console.log("----------");
  console.log(prochainEvent);
  let memEvent = listEvents.filter(
    (pres) => new Date() < new Date(pres.dateEtHeure)
  );
  console.log(memEvent[0]);
  if (memEvent[0]) {
    console.log("update");
    prochainEvent = memEvent[prochainEvent.length - 1];
  }
  else {
    prochainEvent = prochainEvent[0];
  }
  console.log(prochainEvent);//*/

  const months = [
    "Jan.",
    "Fév.",
    "Mars",
    "Avr.",
    "Mai",
    "Juin",
    "Juil.",
    "Août",
    "Sept.",
    "Oct.",
    "Nov.",
    "Déc"
  ];
  const days = [
    "Dimanche",
    "Lundi",
    "Mardi",
    "Mercr.",
    "Jeudi",
    "Vendr.",
    "Samedi"
  ];

  function timestampToString(timestamp) {
    const date = new Date(timestamp);
    let returnString = "";
    returnString =
      days[date.getDay()] +
      " " +
      date.getDate() +
      " " +
      months[date.getMonth()] +
      " " +
      date.getHours() +
      ":" +
      date.getMinutes().toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false
      });
    return returnString;
  }

  return (
    <div className="mainNextEvent toAppear">
      <h3>Next Event</h3>
      {
        dataNextEvent.dateEtHeure != "" ?
          <div className="blockNextEvent">
            <div>{dataNextEvent.titre}</div>
            <div>{timestampToString(dataNextEvent.dateEtHeure).toUpperCase()}</div>
          </div>
          :
          <div className="blockNextEvent">
            Next event coming soon, stay tuned!
          </div>
      }
      <div className="divButtonsNextEvent">
        {
          dataNextEvent.dateEtHeure != "" ?
            <a href={dataNextEvent.lienVideo} target="_blank">
              <button className="buttonLanding">Join the event</button>
            </a>
            :
            <a href="https://www.hecalumni.fr/group/blockchain/2701/about" target="_blank">
              <button className="buttonLanding">See all events</button>
            </a>
        }
        <a href="mailto:blockchainhec@gmail.com">
          <button className="buttonLanding blue">Become a partner</button>
        </a>

      </div>
    </div>
  );
}

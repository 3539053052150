export const listEvents =
  [
    {
      dateEtHeure: "2023-01-12T18:00+01:00",
      description: "AMA LOGION - Update with David Schmitz",
      lienVideo: "https://www.hecalumni.fr/group/blockchain/2701/calendar/ama-logion-blockchain-network-with-david-schmitz/2023/01/12/9677",
      slides: null,
      titre: "AMA LOGION - Update with David Schmitz",
      speaker: {
        nom: "David SCHMITZ",
        photo: {
          fluid: {
            src: "//images.ctfassets.net/zg5peamxhw22/28KJxKHRzLAVKawrKHlTme/4e4f0a8bfdae4f49531d840d225e6945/ama_logion_update.png?w=600&q=100&fm=jpg"
          }
        }
      },
      image: {
        fluid: {
          src: "//images.ctfassets.net/zg5peamxhw22/28KJxKHRzLAVKawrKHlTme/4e4f0a8bfdae4f49531d840d225e6945/ama_logion_update.png?w=600&q=100&fm=jpg"
        }
      }
    },
    {
      dateEtHeure: "2022-12-08T18:30+01:00",
      description: "Beyond Blockchain, the end of centralisation?",
      lienVideo: "https://www.hecalumni.fr/group/blockchain/2701/calendar/-beyond-blockchain-the-end-of-centralization-networking-event/2022/12/08/9517",
      slides: null,
      titre: "Beyond Blockchain, the end of centralisation?",
      speaker: {
        nom: "Michael AMAR, David Prinçay, Pierre PERSON, Claire Balva, Mélodie AMBROISE, Stéphane Daniel, Ilya Zuckerman, Naomie Halioua",
        photo: {
          fluid: {
            src: "//images.ctfassets.net/zg5peamxhw22/1edEKu4hsAFrK8v112f6z4/fc486c7b9e09430511888dd695f380fe/1669101373409.jpeg?w=600&q=100&fm=jpg"
          }
        }
      },
      image: {
        fluid: {
          src: "//images.ctfassets.net/zg5peamxhw22/1edEKu4hsAFrK8v112f6z4/fc486c7b9e09430511888dd695f380fe/1669101373409.jpeg?w=600&q=100&fm=jpg"
        }
      }
    },
    {
      dateEtHeure: "2022-11-17T18:00+01:00",
      description: null,
      lienVideo: "https://youtu.be/l_uyHnQ56gM",
      slides: null,
      titre: "Investors in Web3 - Episode #4",
      speaker: {
        nom: "Marjorie Vigne, Ferdinand Le Tendre",
        photo: null
      },
      image: {
        fluid: {
          src: "//images.ctfassets.net/zg5peamxhw22/79QahAvJ8wjmJyK87fvwn0/a1093f09bcb6707d2778f688e5e57dc4/Investor_in_web_3.jpeg?w=600&q=100&fm=jpg"
        }
      }
    },
    {
      dateEtHeure: "2022-11-08T18:00+01:00",
      description: "🚀 Come and discover the tokenization of real estate.",
      lienVideo: "https://youtu.be/JxeoK1lCySE",
      slides: null,
      titre: "Real estate and Tokenization",
      speaker: {
        nom: "Jean-Marc Jacobson - Charles Cavagolione - Frederic Bertoiat - Jessica Castro Oudni",
        photo: null
      },
      image: {
        fluid: {
          src: "//images.ctfassets.net/zg5peamxhw22/73pz6w7Vtfwij2ADdVLUCI/6e426848586d1e1677f642a54f3b4d59/Real_estate.jpeg?w=600&q=100&fm=jpg"
        }
      }
    },
    {
      dateEtHeure: "2022-10-27T18:00+02:00",
      description: "Blockchain Skills & Jobs #4",
      lienVideo: "https://bit.ly/3EbSTz6",
      slides: null,
      titre: "Blockchain Skills & Jobs #4",
      speaker: {
        nom: "Catherine Atterbury, Paul Frambot, Sébastien Pussiau, Gabriel Begazo, Sébastien Meslé",
        photo: {
          fluid: {
            src: "//images.ctfassets.net/zg5peamxhw22/3iF5YZ52S4RzSvUd3SLXE/d55cedc61863e5ca1f0e1326d2b9bdab/Capture_d_e_cran_2022-10-14_a__12.43.48.png?w=100&q=100&fm=jpg"
          }
        }
      },
      image: {
        fluid: {
          src: "//images.ctfassets.net/zg5peamxhw22/1JJaAQeUVmHJ36K5bfWlMN/b34cf39a753b6d4acfaefc21275024f6/bk_skills_4.jpeg?w=600&q=100&fm=jpg"
        }
      }
    },
    {
      dateEtHeure: "2022-10-25T18:00+02:00",
      description: null,
      lienVideo: "https://bit.ly/3e3uNMr",
      slides: null,
      titre: "Phygital & Metaverse",
      speaker: {
        nom: "Weronika Marciniak, Clément FOUCHER, Gaspard Randon de Grolier, Alexandre Vannucci, Edouard Margain, Robin Sarfati",
        photo: null
      },
      image: {
        fluid: {
          src: "//images.ctfassets.net/zg5peamxhw22/4gz3mn0KfAR69UEHC6pdSE/6e4897ed1a50354bcbdb9ece4d5ed9c3/1664795791770.jfif?w=600&q=100&fm=jpg"
        }
      }
    },
    {
      dateEtHeure: "2022-10-20T18:00+02:00",
      description: null,
      lienVideo: "https://bit.ly/3C2jzjh",
      slides: null,
      titre: "Investors in Web3.0",
      speaker: null,
      image: {
        fluid: {
          src: "//images.ctfassets.net/zg5peamxhw22/5ZZLudwSWXbC8B5IMmbH02/201a0255bb16b343923ef29b937d9696/394571226633a98806c9bb.png?w=600&q=100&fm=jpg"
        }
      }
    },
    {
      dateEtHeure: "2022-10-18T18:00+02:00",
      description: "The Future of Digital assets, with key stakeholders of Web2 and Web3 in Finance",
      lienVideo: "https://bit.ly/3fCKqe1",
      slides: null,
      titre: "Future of Digital Assets",
      speaker: {
        nom: "Victor Busson, John Le Guen, Clarisse Hagège, Philippe Van Hecke, Luc Falempin Folempin, Nicolas Louvet",
        photo: {
          fluid: {
            src: "//images.ctfassets.net/zg5peamxhw22/1cZd8sHs1wpbmux4xeHYSW/1b24e1d83cf282e211316bfd7c03b89c/Capture_d_e_cran_2022-10-14_a__12.37.50.png?w=100&q=100&fm=jpg"
          }
        }
      },
      image: {
        fluid: {
          src: "//images.ctfassets.net/zg5peamxhw22/4J0Lz3l352jX4PMYwvWI82/1e7e3fcf8456777428bd1135f8e693f4/futurs_des_actifs_num.jpeg?w=600&q=100&fm=jpg"
        }
      }
    },
    {
      dateEtHeure: "2022-10-04T18:00+02:00",
      description: null,
      lienVideo: "https://bit.ly/3CuirGJ",
      slides: null,
      titre: "AMA APWine",
      speaker: {
        nom: "Gaspard Peduzzi",
        photo: null
      },
      image: {
        fluid: {
          src: "//images.ctfassets.net/zg5peamxhw22/3ERtBJE3ATGNON7Bxslebw/f2529d918cd4197a9517b6b8ada1294d/1664789954443.jfif?w=600&q=100&fm=jpg"
        }
      }
    },
    {
      dateEtHeure: "2022-09-28T18:00+02:00",
      description: "Blockchain & Sustainability",
      lienVideo: "https://www.linkedin.com/posts/davidservais_sustainability-blockchain-environment-activity-6975710184186769408-B9i-?utm_source=share&utm_medium=member_desktop",
      slides: null,
      titre: "Blockchain & Sustainability",
      speaker: {
        nom: "Vanessa Grellet, Maria Eisner Pelch, Stefan Renton, Bradford van Voorhees",
        photo: {
          fluid: {
            src: "//images.ctfassets.net/zg5peamxhw22/44mq3ks6XWjCHzsRJPPIm3/52a24a941f585b4ab356536cafdc0e37/Bk___sustainability.jpeg?w=100&q=100&fm=jpg"
          }
        }
      },
      image: {
        fluid: {
          src: "//images.ctfassets.net/zg5peamxhw22/3ljpmngEvzON272IiUtdHI/18fee574b7ce07cc431c32cd0bb86f0f/Bk___sustainability.jpeg?w=600&q=100&fm=jpg"
        }
      }
    },
    {
      dateEtHeure: "2022-09-27T18:00+02:00",
      description: "Crypo market debrief / Sept 2022 with Blockchain Experts",
      lienVideo: "https://bit.ly/3RiUnLt",
      slides: null,
      titre: "Crypo market debrief / Sept 2022",
      speaker: {
        nom: "Stanislas de Quénetain",
        photo: {
          fluid: {
            src: "//images.ctfassets.net/zg5peamxhw22/49ARZy0acBdTmSJ5qWMja0/c7db8eee66d39714d3888b46bc4056bd/quenetain.png?w=100&q=100&fm=jpg"
          }
        }
      },
      image: {
        fluid: {
          src: "//images.ctfassets.net/zg5peamxhw22/1KUT2nBzDNDG3wanNnC7Ox/fb6222c5fc9a3d390a79b9b3b1967af6/Copie_de_Blockchains_Skills___Jobs__rouge__11_.png?w=600&q=100&fm=jpg"
        }
      }
    },
    {
      dateEtHeure: "2022-09-22T18:00+02:00",
      description: "Investors in Web3 - Episode #2",
      lienVideo: "https://youtu.be/6MpOD8S2H-U",
      slides: null,
      titre: "Investors in Web3 - Episode #2",
      speaker: {
        nom: "Alexis du Peloux, Dimitri Nitchoun, Alexandre Covello",
        photo: {
          fluid: {
            src: "//images.ctfassets.net/zg5peamxhw22/4xiA33nRHuHzvn99RqClaT/41ece9555cd8613697d03ae2c7e26196/Copie_de_Blockchains_Skills___Jobs__rouge__14_.png?w=100&q=100&fm=jpg"
          }
        }
      },
      image: {
        fluid: {
          src: "//images.ctfassets.net/zg5peamxhw22/75LKT2AVSBpok0JGrV8jot/a521090956c68e63459d42b8d43b9ce3/Copie_de_Blockchains_Skills___Jobs__rouge__14_.png?w=600&q=100&fm=jpg"
        }
      }
    },
    {
      dateEtHeure: "2022-09-20T18:00+02:00",
      description: "AMA Morpho with Paul FRAMBOT",
      lienVideo: "https://youtu.be/cJQXItLq-Jg",
      slides: null,
      titre: "AMA Morpho with Paul Frambot",
      speaker: {
        nom: "Paul FRAMBOT",
        photo: {
          fluid: {
            src: "//images.ctfassets.net/zg5peamxhw22/5W5YdzcPE8okxoYka70iIo/3db7f0cd7314335a09bb3bc364054353/Copie_de_Blockchains_Skills___Jobs__rouge__12_.png?w=100&q=100&fm=jpg"
          }
        }
      },
      image: {
        fluid: {
          src: "//images.ctfassets.net/zg5peamxhw22/3jgeCJv30EzBJDv8iokpA/bb9ce8577d0d0b25d79f08deec553138/Copie_de_Blockchains_Skills___Jobs__rouge__12_.png?w=600&q=100&fm=jpg"
        }
      }
    },
    {
      dateEtHeure: "2022-09-13T18:00+02:00",
      description: "AMA Beem : Decentralized web3 streaming platform-as-a-service",
      lienVideo: "https://youtu.be/ot5uCt156nQ",
      slides: null,
      titre: "AMA BEEM",
      speaker: {
        nom: "Cypien GRAU",
        photo: {
          fluid: {
            src: "//images.ctfassets.net/zg5peamxhw22/7cvu03O4mnoORdaMIBGuyw/4f6bf323e08a2da71a147cdaaa29d97e/Cyprien_GRAU.jpeg?w=100&q=100&fm=jpg"
          }
        }
      },
      image: {
        fluid: {
          src: "//images.ctfassets.net/zg5peamxhw22/2oUxEBIhaWkFhweymwpoBk/a44a511d671f5a9097df50356452f601/Ama_Beem.png?w=600&q=100&fm=jpg"
        }
      }
    },
    {
      dateEtHeure: "2022-09-12T18:00+02:00",
      description: "Blockchain use cases",
      lienVideo: "https://youtu.be/z0IoPdJMHfw",
      slides: null,
      titre: "Blockchain use cases",
      speaker: {
        nom: "Julien Pussiau",
        photo: {
          fluid: {
            src: "//images.ctfassets.net/zg5peamxhw22/3lcJf4MreipldiJ8joW7S0/838a727e5ed4309b167063f9db7dbbca/seb_pussiau.jpg?w=100&q=100&fm=jpg"
          }
        }
      },
      image: {
        fluid: {
          src: "//images.ctfassets.net/zg5peamxhw22/5QzEfn913KJsBda5ozylTB/292dadc87da2e4d0f4f443be08fa121c/Copie_de_Blockchains_Skills___Jobs__rouge__26_.png?w=600&q=100&fm=jpg"
        }
      }
    },
    {
      dateEtHeure: "2022-09-08T18:00+02:00",
      description: "\"DeFi\" ou Decentralized Finance avec Julien Bouteloup.",
      lienVideo: "https://youtu.be/o4XkSRSoLM4",
      slides: null,
      titre: "\"DeFi\" ou Decentralized Finance avec Julien Bouteloup",
      speaker: {
        nom: "Julien Bouteloup",
        photo: {
          fluid: {
            src: "//images.ctfassets.net/zg5peamxhw22/54RyJJuD22tunDyYGoVTrf/5b0cc11b6f22ab138680053da7abc59e/DeFi_et_J_bouteloup.jpg?w=100&q=100&fm=jpg"
          }
        }
      },
      image: {
        fluid: {
          src: "//images.ctfassets.net/zg5peamxhw22/54RyJJuD22tunDyYGoVTrf/5b0cc11b6f22ab138680053da7abc59e/DeFi_et_J_bouteloup.jpg?w=600&q=100&fm=jpg"
        }
      }
    },
    {
      dateEtHeure: "2022-09-06T18:00+02:00",
      description: null,
      lienVideo: "https://youtu.be/KqCVbQrzC4M",
      slides: null,
      titre: "ETHCC Feedback & The Merge",
      speaker: {
        nom: "V Grellet, J de Trychey, J Bouteloup, R Berrehili",
        photo: {
          fluid: {
            src: "//images.ctfassets.net/zg5peamxhw22/2P17k5aG9iPwn9Smm9xtxW/03c61968515bc62511862831644e4650/Copie_de_Blockchains_Skills___Jobs__rouge__18_.png?w=100&q=100&fm=jpg"
          }
        }
      },
      image: {
        fluid: {
          src: "//images.ctfassets.net/zg5peamxhw22/2P17k5aG9iPwn9Smm9xtxW/03c61968515bc62511862831644e4650/Copie_de_Blockchains_Skills___Jobs__rouge__18_.png?w=600&q=100&fm=jpg"
        }
      }
    },
    {
      dateEtHeure: "2022-07-12T18:00+02:00",
      description: "METAVERSE - Episode#1",
      lienVideo: "https://www.hecalumni.fr/group/blockchain/2701/calendar/metaverse-episode-1/2022/07/12/9119",
      slides: null,
      titre: "METAVERSE - Episode#1",
      speaker: {
        nom: "Julien Keller, Nicolas Fratini",
        photo: {
          fluid: {
            src: "//images.ctfassets.net/zg5peamxhw22/1Zvdd7yZ5S1ACxfWrXcXtx/60abe54ac09bd4abfbe864fcb65122c0/Speakers.JPG?w=100&q=100&fm=jpg"
          }
        }
      },
      image: {
        fluid: {
          src: "//images.ctfassets.net/zg5peamxhw22/4QXFQMDC8zuAEtGqvECZJi/dea463975bfc8c07c3cd6c34a70e9c41/Copie_de_Blockchains_Skills___Jobs__rouge__7_.png?w=600&q=100&fm=jpg"
        }
      }
    },
    {
      dateEtHeure: "2022-07-07T18:00+02:00",
      description: "Feedback from 2 VCs (Venture Capital) in WEB3  (Crypto, Blockchain, NFTs, DAOs,...)",
      lienVideo: "https://www.hecalumni.fr/group/blockchain/2701/calendar/-investors-in-web3-episode-1/2022/07/07/9106",
      slides: null,
      titre: "\"Investors in WEB3\" - Episode#1",
      speaker: {
        nom: "Julien Bouteloup, Franck Desvignes",
        photo: {
          fluid: {
            src: "//images.ctfassets.net/zg5peamxhw22/4F5DBbDNXSDvewavyAjBdz/e7236247ed19809edc221c56bfcc54fb/Copie_de_Blockchains_Skills___Jobs__rouge__10_.png?w=100&q=100&fm=jpg"
          }
        }
      },
      image: {
        fluid: {
          src: "//images.ctfassets.net/zg5peamxhw22/7msvwFi7cL9RoynRoXLau4/f070bc4eeec0f69704c303e3a2a8ff0f/Copie_de_Blockchains_Skills___Jobs__rouge__10_.png?w=600&q=100&fm=jpg"
        }
      }
    },
    {
      dateEtHeure: "2022-07-05T18:00+02:00",
      description: "NFT.NYC feedback with Michael AMAR",
      lienVideo: "https://www.hecalumni.fr/group/blockchain/2701/calendar/-nft-nyc-avec-michael-amar/2022/07/05/9104",
      slides: null,
      titre: "NFT.NYC feedback with Michael AMAR",
      speaker: {
        nom: "Michael AMAR",
        photo: {
          fluid: {
            src: "//images.ctfassets.net/zg5peamxhw22/37HLcnmcZKLF2aAdHsVT46/17dfb3929ba69532bf4b208d721ccad9/IMG_0436__1_.jpeg?w=100&q=100&fm=jpg"
          }
        }
      },
      image: {
        fluid: {
          src: "//images.ctfassets.net/zg5peamxhw22/2mmaQpcQxzPgfc2kwRSFqG/d6085c9cf7162c149aa1cb7a436f684c/Copie_de_Blockchains_Skills___Jobs__rouge__9_.png?w=600&q=100&fm=jpg"
        }
      }
    },
    {
      dateEtHeure: "2022-06-30T18:00+02:00",
      description: "NFT trends from Europe & Asia with 4 entrepreneurs",
      lienVideo: "https://youtu.be/qdsVdVEzJ-4",
      slides: null,
      titre: "NFT trends from Europe & Asia with 4 entrepreneurs",
      speaker: {
        nom: "Lucie-Éléonore Riveron, Frederic H Saurat, Romain Verlomme-Fried- FRIED, Franck & Damien Dupont, Matthieu QUINIOU",
        photo: {
          fluid: {
            src: "//images.ctfassets.net/zg5peamxhw22/7xXuL4uppdXjiosQmpTubY/ce617ce74fb04c0e31e40d3764722fa7/Speakers.JPG?w=100&q=100&fm=jpg"
          }
        }
      },
      image: {
        fluid: {
          src: "//images.ctfassets.net/zg5peamxhw22/3ZQjjqqwI2OiDBUew5QdIq/79077f0e0331b2a9e35f3a129e5586ab/Copie_de_Blockchains_Skills___Jobs__rouge__6_.png?w=600&q=100&fm=jpg"
        }
      }
    },
    {
      dateEtHeure: "2022-06-23T18:00+02:00",
      description: "Round Table - 4 founders - “NFT & MUSIC \" : \"What are the utilities of NFTs in Music? How NFTS Are Forging a New Creative Economy for Musicians”?",
      lienVideo: "https://youtu.be/cHhdjsDj_aY",
      slides: null,
      titre: "“NFT & MUSIC \" Episode#1",
      speaker: {
        nom: "S Schweitzer, LM Dhellemmes, C Riedi,  G Davoudian",
        photo: {
          fluid: {
            src: "//images.ctfassets.net/zg5peamxhw22/4bVsdGb7ux5IJyAHApMGGa/ab8c9e8a1c091124ea46a5d3379bc6cf/Capture.PNG?w=100&q=100&fm=jpg"
          }
        }
      },
      image: {
        fluid: {
          src: "//images.ctfassets.net/zg5peamxhw22/3alT9ZbeNxSq3Ca2aiRMez/7e72a0b9ed394b99265553c3d095d0b4/Copie_de_Blockchains_Skills___Jobs__rouge__8_.png?w=600&q=100&fm=jpg"
        }
      }
    },
    {
      dateEtHeure: "2022-06-14T18:00+02:00",
      description: "Le rdv des recruteurs, candidats et formateurs du web3",
      lienVideo: "https://youtu.be/58CoeIL26mU",
      slides: null,
      titre: "Episode 3 \"Blockchain Skills & Jobs\" with Stack Talent",
      speaker: {
        nom: "Paul WOLF",
        photo: {
          fluid: {
            src: "//images.ctfassets.net/zg5peamxhw22/6MxI9o1bLMWylt5vOg9w1j/3a6d13975193640cb0ca07b168a000fd/P_Wolf.jpg?w=100&q=100&fm=jpg"
          }
        }
      },
      image: {
        fluid: {
          src: "//images.ctfassets.net/zg5peamxhw22/47E6hvS6eWTGGAkBja7oU0/716bc9d7bd62c9ded1add0d206bddc74/bk_skills___jobs.png?w=600&q=100&fm=jpg"
        }
      }
    },
    {
      dateEtHeure: "2022-06-07T18:00+02:00",
      description: "Crypto Market Debrief / June 2022",
      lienVideo: "https://youtu.be/oKS4uZGcNSM",
      slides: null,
      titre: "Crypto Market Debrief / June 2022",
      speaker: {
        nom: "Stanislas de Quénetain",
        photo: {
          fluid: {
            src: "//images.ctfassets.net/zg5peamxhw22/49ARZy0acBdTmSJ5qWMja0/c7db8eee66d39714d3888b46bc4056bd/quenetain.png?w=100&q=100&fm=jpg"
          }
        }
      },
      image: {
        fluid: {
          src: "//images.ctfassets.net/zg5peamxhw22/7B6PWSQ0mC5YXHX4mFFArr/55a896d3fb729722e0de0ec41d51ce95/crypto_market_debrief.JPG?w=600&q=100&fm=jpg"
        }
      }
    },
    {
      dateEtHeure: "2022-06-02T18:00+02:00",
      description: "“Ask Me Anything (AMA) La Diversité du Cinéma Français (La DCF) / KlapCoin\"",
      lienVideo: "https://youtu.be/Cj_ZhDhCadA",
      slides: null,
      titre: "“Ask Me Anything (AMA) La Diversité du Cinéma Français (La DCF) / KlapCoin\"",
      speaker: {
        nom: "Sarah LELOUCH, Joël GIROD, Sabine TELIER, Fabien BERGER",
        photo: {
          fluid: {
            src: "//images.ctfassets.net/zg5peamxhw22/7fhebmljnqeHZczmBf6CJj/3521c22e9d72afa612aa590bfc80b8f9/KlapCoin.png?w=100&q=100&fm=jpg"
          }
        }
      },
      image: {
        fluid: {
          src: "//images.ctfassets.net/zg5peamxhw22/5eU8LpT4SBcifdMBfkTmBH/a63a4b17bb38233a0d657339d9119d53/KlapCoin.png?w=600&q=100&fm=jpg"
        }
      }
    },
    {
      dateEtHeure: "2022-05-12T18:00+02:00",
      description: "\"NFTs ecosystems,  NFT Economic & Legal value\" - 6 panelists Round table",
      lienVideo: "https://youtu.be/G2E-orwZVL0",
      slides: null,
      titre: "\"NFTs ecosystems,  NFT Economic & Legal value\" - 6 panelists Round table",
      speaker: {
        nom: "J Bouteloup, S Borget, P Adam-Kalfon, R Verlomme-Fried, P Guigourese, P Valles",
        photo: {
          fluid: {
            src: "//images.ctfassets.net/zg5peamxhw22/2xP56IaJ2fst9ThIw683Ly/b9f92783b42ea6ee5cc6bcfcb0cd729f/12_mai_speakers.PNG?w=100&q=100&fm=jpg"
          }
        }
      },
      image: {
        fluid: {
          src: "//images.ctfassets.net/zg5peamxhw22/6sUtygeJWdo8jb9bmvMgg7/8f71f4309cf46b4da2ee88121add96c1/Template_Blockchain_HEC__29_.png?w=600&q=100&fm=jpg"
        }
      }
    },
    {
      dateEtHeure: "2022-04-21T18:00+01:00",
      description: null,
      lienVideo: "https://youtu.be/jB5q1xA2I-c",
      slides: null,
      titre: "AMA \"HEXA SOLUTIONS\"",
      speaker: {
        nom: "Sébastien DAMERY",
        photo: {
          fluid: {
            src: "//images.ctfassets.net/zg5peamxhw22/4L7o4plbcgTqeQBp1Rf2pk/a44f2db66986680697c3559e6a093a84/Template_Blockchain_HEC__4_.png?w=100&q=100&fm=jpg"
          }
        }
      },
      image: {
        fluid: {
          src: "//images.ctfassets.net/zg5peamxhw22/4Myc9RwSnIQQpMqDGzVtjr/08bd8d8d410ab5de59f24b0db1d8f10d/Template_Blockchain_HEC__4_.png?w=600&q=100&fm=jpg"
        }
      }
    },
    {
      dateEtHeure: "2022-04-12T18:00+02:00",
      description: "Crypto Market Debrief - with Stanislas de Quénetain",
      lienVideo: "https://youtu.be/05isKIFq5wU",
      slides: null,
      titre: "Crypto Market Debrief - April 2022",
      speaker: {
        nom: "Stanislas de Quénetain",
        photo: {
          fluid: {
            src: "//images.ctfassets.net/zg5peamxhw22/49ARZy0acBdTmSJ5qWMja0/c7db8eee66d39714d3888b46bc4056bd/quenetain.png?w=100&q=100&fm=jpg"
          }
        }
      },
      image: {
        fluid: {
          src: "//images.ctfassets.net/zg5peamxhw22/2Iw2SgaBAye5m6GOZrzGmu/2e59c34afb64aa79206a25ea6d1516d0/blockchain_expert_2_sep.jpg?w=600&q=100&fm=jpg"
        }
      }
    },
    {
      dateEtHeure: "2022-04-07T18:00+02:00",
      description: "AMA ConsenSys et 101 \"Ethereum 2.0\"",
      lienVideo: "https://youtu.be/3rJFWFaSIDs",
      slides: null,
      titre: "AMA ConsenSys et 101 \"Ethereum 2.0\"",
      speaker: {
        nom: "Benjamin LAVERGNE",
        photo: {
          fluid: {
            src: "//images.ctfassets.net/zg5peamxhw22/4HSAsGrmv5Yi6k26TnaKZR/25dfa350dfe85fbb2101ab66c6813168/B_LAVERGNE.jpeg?w=100&q=100&fm=jpg"
          }
        }
      },
      image: {
        fluid: {
          src: "//images.ctfassets.net/zg5peamxhw22/39qA80LwsSO4ki1MHSyk2k/c652018d343e23bee959ef1dbdcf7ef5/AMA_ConsenSys___101_-Ethereum_2.0-.jpeg?w=600&q=100&fm=jpg"
        }
      }
    },
    {
      dateEtHeure: "2022-03-30T18:00+02:00",
      description: "AMA \"Paris NFT Day\" et \"PBWS\" ",
      lienVideo: "https://youtu.be/B64U9m50HVY?t=15",
      slides: null,
      titre: "AMA \"Paris NFT Day\" et \"PBWS\" ",
      speaker: {
        nom: "Michael AMAR",
        photo: {
          fluid: {
            src: "//images.ctfassets.net/zg5peamxhw22/37HLcnmcZKLF2aAdHsVT46/17dfb3929ba69532bf4b208d721ccad9/IMG_0436__1_.jpeg?w=100&q=100&fm=jpg"
          }
        }
      },
      image: {
        fluid: {
          src: "//images.ctfassets.net/zg5peamxhw22/24XTxbh06b3yN3O7IyxxGn/06672e048c9c166a14377b76045b7e74/Paris_NFT_Day_-_PBWS.jpg?w=600&q=100&fm=jpg"
        }
      }
    },
    {
      dateEtHeure: "2022-03-17T18:00+01:00",
      description: null,
      lienVideo: "https://www.youtube.com/watch?v=QVjV8vrJ4wU",
      slides: null,
      titre: "Episode#2 \"Blockchain Skills & Jobs\"",
      speaker: null,
      image: {
        fluid: {
          src: "//images.ctfassets.net/zg5peamxhw22/1bk9iYCH0sD6cKMBpy1G12/7148a5e1029919d24aa05887a81ed965/Template_Blockchain_HEC__20_.png?w=600&q=100&fm=jpg"
        }
      }
    },
    {
      dateEtHeure: "2022-02-03T18:00+01:00",
      description: null,
      lienVideo: "https://youtu.be/owXA_pou2Rs",
      slides: null,
      titre: "AMA DOGAMI",
      speaker: {
        nom: "Bilal El Alamy",
        photo: {
          fluid: {
            src: "//images.ctfassets.net/zg5peamxhw22/2u7lqABf62qCLBmVUu5XaG/35574f84d652c6794bcfbc4e5019d476/Blockchain-experts_18mai__Vid__o___Pr__sentation__169____3_.png?w=100&q=100&fm=jpg"
          }
        }
      },
      image: {
        fluid: {
          src: "//images.ctfassets.net/zg5peamxhw22/7a0LWF95sWR70MQVuHe62h/719c05584a86d1e6eea5b36c76b9ccd9/Blockchain-experts_18mai__Vid__o___Pr__sentation__169____3_.png?w=600&q=100&fm=jpg"
        }
      }
    },
    {
      dateEtHeure: "2022-02-01T18:00+01:00",
      description: null,
      lienVideo: "https://www.youtube.com/watch?v=avX0bbbyjcQ",
      slides: null,
      titre: "Crypto Market Debrief",
      speaker: {
        nom: "Stanislas de Quénetain",
        photo: {
          fluid: {
            src: "//images.ctfassets.net/zg5peamxhw22/49ARZy0acBdTmSJ5qWMja0/c7db8eee66d39714d3888b46bc4056bd/quenetain.png?w=100&q=100&fm=jpg"
          }
        }
      },
      image: {
        fluid: {
          src: "//images.ctfassets.net/zg5peamxhw22/7B6PWSQ0mC5YXHX4mFFArr/55a896d3fb729722e0de0ec41d51ce95/crypto_market_debrief.JPG?w=600&q=100&fm=jpg"
        }
      }
    },
    {
      dateEtHeure: "2022-01-25T18:00+01:00",
      description: null,
      lienVideo: "https://youtu.be/3NY9RgY6RTE",
      slides: {
        "file": {
          "url": "//downloads.ctfassets.net/zg5peamxhw22/L2qX4O7UBbx1AtBqbsnGd/573f97636de7d49b832c369c151078cd/Blockchain_HEC_25_janvier_2022_pdf.pdf"
        }
      },
      titre: "\"The Metaverses\" avec Pierre PAPERON",
      speaker: {
        nom: "Pierre Paperon",
        photo: {
          fluid: {
            src: "//images.ctfassets.net/zg5peamxhw22/1Ljd864hRnUIU0wgMUWhNC/3b204a9b6a6b1124019cbe363c81a982/Pierre-Paperon-AQUAE-PANORAMA-2019__Photo-Faust-Favart_200__1_.jpg?w=100&q=100&fm=jpg"
          }
        }
      },
      image: {
        fluid: {
          src: "//images.ctfassets.net/zg5peamxhw22/4QX4LFGNhAdtRBbhrdvy3g/bccb3518899bf2d670f490636848f0ff/Blockchain-experts_18mai__Vid__o___Pr__sentation__169__.png?w=600&q=100&fm=jpg"
        }
      }
    },
    {
      dateEtHeure: "2022-01-20T18:30+01:00",
      description: null,
      lienVideo: "https://youtu.be/MKrRAAVosLg",
      slides: null,
      titre: "AMA - The Sandbox",
      speaker: {
        nom: "Sébastien BORGET",
        photo: {
          fluid: {
            src: "//images.ctfassets.net/zg5peamxhw22/3JeBGtIq3avD8H8dl38mVz/cf3a9f19d2f75078ce6892b7118c0d2b/The_Sandbox.png?w=100&q=100&fm=jpg"
          }
        }
      },
      image: {
        fluid: {
          src: "//images.ctfassets.net/zg5peamxhw22/5xaIDNxFHqtM9YO5qWheD1/fa2fdc88a4c8d1b43f9919393a8b544e/The_Sandbox.png?w=600&q=100&fm=jpg"
        }
      }
    },
    {
      dateEtHeure: "2022-01-18T18:00+01:00",
      description: null,
      lienVideo: "https://youtu.be/hdjr-WVc1OA",
      slides: null,
      titre: "AMA Cardashift - with the founders",
      speaker: {
        nom: "Cardashift Founders",
        photo: {
          fluid: {
            src: "//images.ctfassets.net/zg5peamxhw22/sjYldRc611zE4T97RJQMM/020283a24db2f2c358c9c0e65179aa45/cardahift_logo.png?w=100&q=100&fm=jpg"
          }
        }
      },
      image: {
        fluid: {
          src: "//images.ctfassets.net/zg5peamxhw22/6xQrkt5CVUJR2HmJEun9Lj/80eb0f2f5d507475f5c225010893d917/Blockchain-experts_18mai__Vid__o___Pr__sentation__169____4_.png?w=600&q=100&fm=jpg"
        }
      }
    },
    {
      dateEtHeure: "2022-01-13T18:00+01:00",
      description: "Meetup \"Blockchain skills & jobs\" with recruiters, candidates and trainers in the Blockchains & Crypto space",
      lienVideo: "https://youtu.be/71pvDG1Nxxo",
      slides: null,
      titre: "Blockchain skills & jobs",
      speaker: {
        nom: "Many Speakers",
        photo: {
          fluid: {
            src: "//images.ctfassets.net/zg5peamxhw22/30nr5lCGg3yxcpUE9nUNrh/c4f036ea1fa0164a0d594a7c9f20c252/blockchainHEC_logo.png?w=100&q=100&fm=jpg"
          }
        }
      },
      image: {
        fluid: {
          src: "//images.ctfassets.net/zg5peamxhw22/1n0KnfimAEdBuR5zDBY1HZ/4097f75f8e4fd6a3240adf4a9d3d08c4/Blockchains_Skills___Jobs__rouge.png?w=600&q=100&fm=jpg"
        }
      }
    },
    {
      dateEtHeure: "2022-01-13T18:00+01:00",
      description: "Oana Ladret Piciorus -   Nomadic Labs - recruiter pitch",
      lienVideo: "https://youtu.be/1cSOWU2edWY",
      slides: null,
      titre: "Pitch recruteurs - Nomadic Labs - Blockchain skills & jobs - Episode 1",
      speaker: {
        nom: "Oana Ladret Piciorus",
        photo: {
          fluid: {
            src: "//images.ctfassets.net/zg5peamxhw22/5j2Y7lj3xEPmN1Mt5rXNMu/b6994f7fa79dd0d8d0d5ad3f4bb9f62c/Oana_Ladret_Piciorus.jpg?w=100&q=100&fm=jpg"
          }
        }
      },
      image: {
        fluid: {
          src: "//images.ctfassets.net/zg5peamxhw22/4KCF7ew8XvD9B3YR93CpTf/d3a70e706c562e07a6cdd9c6114317db/Pitch_recruteur_-_nomadic.png?w=600&q=100&fm=jpg"
        }
      }
    },
    {
      dateEtHeure: "2022-01-13T18:00+01:00",
      description: "Adrien Hubert - COO - Smartchain",
      lienVideo: "https://youtu.be/I4p7YSqW4FA",
      slides: null,
      titre: "Pitch recruteurs - Smart Chain- Blockchain skills & jobs - Episode 1",
      speaker: {
        nom: "Adrien HUBERT",
        photo: {
          fluid: {
            src: "//images.ctfassets.net/zg5peamxhw22/5UzIm0i8055qF7UUSJsNfk/337aecfd353837fed25684c8b1efdf23/adrien_hubert.jpg?w=100&q=100&fm=jpg"
          }
        }
      },
      image: {
        fluid: {
          src: "//images.ctfassets.net/zg5peamxhw22/QdWue8SeGD9ehfMJn5Y3O/e5099b46daddfe38ce895af5bc2f0c4d/Pitch_recruteur_-_smartchain.png?w=600&q=100&fm=jpg"
        }
      }
    },
    {
      dateEtHeure: "2022-01-13T18:00+01:00",
      description: "tch recruteurs - Keeex - Blockchain skills & jobs - Episode 1 - Laurent Henocque - CEO Keeex",
      lienVideo: "https://youtu.be/WPfbWONyssI",
      slides: null,
      titre: "Pitch recruteurs - Keeex - Blockchain skills & jobs - Episode 1",
      speaker: {
        nom: "Laurent Henocque",
        photo: {
          fluid: {
            src: "//images.ctfassets.net/zg5peamxhw22/3QmRPkQVWEodEm6KwDcMBn/5b77d0e1ec67064c71110140382669df/zebox-portrait-330x330-startup-ceo-keeex-henocque-laurent.jpg?w=100&q=100&fm=jpg"
          }
        }
      },
      image: {
        fluid: {
          src: "//images.ctfassets.net/zg5peamxhw22/6IZSRytiXV4oInM96n4o3Y/3f2a96227e11ca8e2fa0d3f3da9989cd/Pitch_recruteur_-_Keeex.png?w=600&q=100&fm=jpg"
        }
      }
    },
    {
      dateEtHeure: "2022-01-13T18:00+01:00",
      description: "Cabinet de recrutement - BlockchainContract - Yacine Alyiat",
      lienVideo: "https://youtu.be/_36ZdfhDAwY",
      slides: null,
      titre: "Cabinet de recrutement - BlockchainContract - Yacine Alyiat",
      speaker: {
        nom: "Yacine Alyiat",
        photo: {
          fluid: {
            src: "//images.ctfassets.net/zg5peamxhw22/1glQxhusR2vqddY91BLSbK/68cb3de32518fac8a2b07042f8dd7c6b/yacine_Alyiat.jpg?w=100&q=100&fm=jpg"
          }
        }
      },
      image: {
        fluid: {
          src: "//images.ctfassets.net/zg5peamxhw22/6Dq4j9mvwP0WVtOgVdSkou/848b3bac04791c2e44dc9cea5c9df0c8/Pitch_recruteur_-_blockchaincontract.png?w=600&q=100&fm=jpg"
        }
      }
    },
    {
      dateEtHeure: "2022-01-13T18:00+01:00",
      description: "Nomadic Labs - Training pitch - Matthias Hiron  ",
      lienVideo: "https://youtu.be/0C8IovUeGrw",
      slides: null,
      titre: "Pitch Formateurs - Nomadic Labs - Matthias HIRON",
      speaker: {
        nom: "Mathias HIRON",
        photo: {
          fluid: {
            src: "//images.ctfassets.net/zg5peamxhw22/4eN6ZHAuHdSI4SenCsHViA/f2ec138a47f967d490df509dcaba6ec0/mathias_hiron.jpg?w=100&q=100&fm=jpg"
          }
        }
      },
      image: {
        fluid: {
          src: "//images.ctfassets.net/zg5peamxhw22/1HUZA01hsTBvh9pQNbQbxh/bfadd57cdf817ba9ea9f841f61ba3446/Pitch_Formateurs_-_Mathias_Hiron_-_Nomadic.png?w=600&q=100&fm=jpg"
        }
      }
    },
    {
      dateEtHeure: "2022-01-13T18:00+01:00",
      description: "Pitch Formateurs - Pierre Paperon ",
      lienVideo: "https://youtu.be/1OES28LqZNI",
      slides: null,
      titre: "Pitch Formateurs - Pierre Paperon ",
      speaker: null,
      image: {
        fluid: {
          src: "//images.ctfassets.net/zg5peamxhw22/cfiod1NcFc7fo44VG19lV/51cbc661a66977f2220daad6d33735e1/Pitch_formateur_-_Pierre_Paperon.png?w=600&q=100&fm=jpg"
        }
      }
    },
    {
      dateEtHeure: "2022-01-13T18:00+01:00",
      description: "Pitch Formateurs - PWC - Klara SOK",
      lienVideo: "https://youtu.be/lPNRKyjvT1A",
      slides: null,
      titre: "Pitch Formateurs - PWC - Klara SOK",
      speaker: {
        nom: "Klara SOK",
        photo: {
          fluid: {
            src: "//images.ctfassets.net/zg5peamxhw22/1NTiyz7wotE5upl1R5ctYg/3806f8f781e40455a4f67113b75587f0/klara_sok.jpg?w=100&q=100&fm=jpg"
          }
        }
      },
      image: {
        fluid: {
          src: "//images.ctfassets.net/zg5peamxhw22/1zjXDkSRTaJKcJlnIfIl9N/a2e3a8a9cba6b0251f6102865912c621/Pitch_Formateurs_-_Klara_Sok_-_Pwc.png?w=600&q=100&fm=jpg"
        }
      }
    },
    {
      dateEtHeure: "2022-01-13T18:00+01:00",
      description: "Blockchain Jobs Market perspectives - Jan 2022 - David & Karolina -",
      lienVideo: "https://youtu.be/bgpPIBDda3k",
      slides: null,
      titre: "Blockchain Jobs Market perspectives - Jan 2022 - David & Karolina -",
      speaker: {
        nom: "David SERVAIS - Karolina GORNA",
        photo: null
      },
      image: {
        fluid: {
          src: "//images.ctfassets.net/zg5peamxhw22/1p2MZjcfzsjNy4ml8tz4iw/6f98f2fc703f717ac4d477b6b4696de7/Blockchain_skills___jobs_-_Ep1_-_Job_market.png?w=600&q=100&fm=jpg"
        }
      }
    },
    {
      dateEtHeure: "2022-01-13T18:00+01:00",
      description: "AMA Wallcrypt - Jean Luc Lasquellec",
      lienVideo: "https://youtu.be/bldEOuovP9M",
      slides: null,
      titre: "AMA Wallcrypt - Jean Luc Lasquellec",
      speaker: {
        nom: "Jean Luc Lasquellec",
        photo: {
          fluid: {
            src: "//images.ctfassets.net/zg5peamxhw22/67YBNj5kICKAPTa2CTyIAS/4e8a965cb253371b4a777bc9367ce611/jl_lasquellec.jpg?w=100&q=100&fm=jpg"
          }
        }
      },
      image: {
        fluid: {
          src: "//images.ctfassets.net/zg5peamxhw22/5oEBll6WfPkL0j5CNjlbu9/98ab8642f4ece2e77dcdaaf5c2b942c1/Ama_wallcrypt.png?w=600&q=100&fm=jpg"
        }
      }
    },
    {
      dateEtHeure: "2022-01-11T18:00+01:00",
      description: null,
      lienVideo: "https://youtu.be/JesoM7vcEMI",
      slides: null,
      titre: "AMA “SmartLink” with Marius Campos",
      speaker: {
        nom: "Marius CAMPOS",
        photo: {
          fluid: {
            src: "//images.ctfassets.net/zg5peamxhw22/unCPKFUfPw4ZCPfZXW0oR/cdbd1eb94aa8e5e8901963ee4ceb8c3b/marius_campos_-_PP-linkedin.jpg?w=100&q=100&fm=jpg"
          }
        }
      },
      image: {
        fluid: {
          src: "//images.ctfassets.net/zg5peamxhw22/5ZMHh94QRVUHWNJPrrbz1o/92e61ef30bdfb0962db464d49179ed59/Copie_de_AMA_Marius_Smartlink.png?w=600&q=100&fm=jpg"
        }
      }
    },
    {
      dateEtHeure: "2022-01-06T18:00+01:00",
      description: null,
      lienVideo: "https://youtu.be/5unNiGPJ3Sw",
      slides: null,
      titre: "AMA LOGION - Web3 legal protection",
      speaker: {
        nom: "Stéphane FLACHER - David SCHMITZ - Elie AUVRAY",
        photo: {
          fluid: {
            src: "//images.ctfassets.net/zg5peamxhw22/3AvKxbxQTR6LjsvEJnmwUW/a9a9eace5a298b9946184c9a4875e524/Blockchain-experts_18mai__5_.png?w=100&q=100&fm=jpg"
          }
        }
      },
      image: {
        fluid: {
          src: "//images.ctfassets.net/zg5peamxhw22/4GcEovgB11lay7fn8EjWlT/1365e83330e70eb48f89d98bda87e3c5/Blockchain-experts_18mai__8_.png?w=600&q=100&fm=jpg"
        }
      }
    },
    {
      dateEtHeure: "2022-01-06T18:00+01:00",
      description: null,
      lienVideo: "https://youtu.be/o2Zq6Sxw8oY",
      slides: null,
      titre: "LOGION - La Génèse",
      speaker: {
        nom: "David SCHMITZ",
        photo: {
          fluid: {
            src: "//images.ctfassets.net/zg5peamxhw22/60SsKdHCzsPtvHTjz1RP7i/df0f05ece51ad6f4cf985497e2a74711/DavidSchmitz-300x300.png?w=100&q=100&fm=jpg"
          }
        }
      },
      image: {
        fluid: {
          src: "//images.ctfassets.net/zg5peamxhw22/2rDzArYSfe43KEmPp1eILx/91086e0a6055a256b6841a43c35456b7/logion_la_g__n__se.PNG?w=600&q=100&fm=jpg"
        }
      }
    },
    {
      dateEtHeure: "2022-01-04T18:00+01:00",
      description: null,
      lienVideo: "https://youtu.be/lfmPUU509T4",
      slides: null,
      titre: "101 \"Cardashift\" with Tangui Friant & Yannis Baala",
      speaker: {
        nom: "Yannis Baala - Tangui Friant",
        photo: {
          fluid: {
            src: "//images.ctfassets.net/zg5peamxhw22/3SAfUgxy6b7ZJYBH4Utjqm/3a4b2953415113e55beb8ae755b416c4/AMA_Cardashift__2_.png?w=100&q=100&fm=jpg"
          }
        }
      },
      image: {
        fluid: {
          src: "//images.ctfassets.net/zg5peamxhw22/70BV8VpZh7rlJrNlRTMctx/8059753545a410882cb7466958b60a9b/Blockchain-experts_18mai__Vid__o___Pr__sentation__169____4_.png?w=600&q=100&fm=jpg"
        }
      }
    },
    {
      dateEtHeure: "2021-12-14T18:00+01:00",
      description: "AMA (Ask Me Anything about) LUGH ",
      lienVideo: "https://www.youtube.com/watch?v=1KphsR3SQkY",
      slides: null,
      titre: "AMA (Ask Me Anything about) LUGH with Alban Vendeuvre",
      speaker: {
        nom: "Alban Vendeuvre",
        photo: {
          fluid: {
            src: "//images.ctfassets.net/zg5peamxhw22/6q3GegCrE4rUgp4TUOmMpJ/c7ad4c3a17a0d96114de0d06d75c8cb3/Alban_V.JPG?w=100&q=100&fm=jpg"
          }
        }
      },
      image: {
        fluid: {
          src: "//images.ctfassets.net/zg5peamxhw22/6wMbjiV7GMss4pIt6aix5Q/91fae7edd7fee2dddd46ea11aaa64ac9/lugh_enr.png?w=600&q=100&fm=jpg"
        }
      }
    },
    {
      dateEtHeure: "2021-12-09T18:00+01:00",
      description: null,
      lienVideo: "https://www.youtube.com/watch?v=ePiTXNsBD2k",
      slides: {
        "file": {
          "url": "//assets.ctfassets.net/zg5peamxhw22/54WquR1YGimMyaohdYtZ2F/7846215949b63cf4183bd42cca178b22/Ethique_et_blockchain_091221-Compatibility_Mode-.pdf"
        }
      },
      titre: "Blockchain & Ethique",
      speaker: {
        nom: "Pierre Paperon",
        photo: {
          fluid: {
            src: "//images.ctfassets.net/zg5peamxhw22/1Ljd864hRnUIU0wgMUWhNC/3b204a9b6a6b1124019cbe363c81a982/Pierre-Paperon-AQUAE-PANORAMA-2019__Photo-Faust-Favart_200__1_.jpg?w=100&q=100&fm=jpg"
          }
        }
      },
      image: {
        fluid: {
          src: "//images.ctfassets.net/zg5peamxhw22/2pCd8H9x5OQyLq1oEKXB1x/98f6586923dbf396d57c717fbcda6588/P_Paperon_-_25_nov.png?w=600&q=100&fm=jpg"
        }
      }
    },
    {
      dateEtHeure: "2021-12-07T18:30+01:00",
      description: "Digitization of Finance - DeFi & TradFi, CBDCs, Playtoearn",
      lienVideo: "https://www.youtube.com/watch?v=FjbEcNDlZcg",
      slides: {
        "file": {
          "url": "//assets.ctfassets.net/zg5peamxhw22/2qOcYhyLlAonrD0uAGkcCy/a013d1e2bc728caf0fd21669bf8419f1/2021-12-07_HEC-Blockchain_Finance-Digitalization_SGFORGE.pdf"
        }
      },
      titre: "Digitization of Finance",
      speaker: {
        nom: "Pauline ADAM-KALFON - Klara SOK - Stéphane DUZAN",
        photo: {
          fluid: {
            src: "//images.ctfassets.net/zg5peamxhw22/6qgYJJ2yXWeq4giKjZGhfP/6864b319b80780aaeb37391ce0396545/photo_pauline___stephane___klara.JPG?w=100&q=100&fm=jpg"
          }
        }
      },
      image: {
        fluid: {
          src: "//images.ctfassets.net/zg5peamxhw22/4jqmPT9Ywv9mpyM2WAIuV7/c3fcb059be1fcafff21cf047f8f15b30/pwc___sg_-_7_dec.png?w=600&q=100&fm=jpg"
        }
      }
    },
    {
      dateEtHeure: "2021-12-02T18:00+01:00",
      description: "\"Crypto market debrief”",
      lienVideo: "https://youtu.be/emHohnMWgAM",
      slides: null,
      titre: "\"Crypto market debrief” by Blockchains-Expert ",
      speaker: {
        nom: "Stanislas de Quénetain",
        photo: {
          fluid: {
            src: "//images.ctfassets.net/zg5peamxhw22/49ARZy0acBdTmSJ5qWMja0/c7db8eee66d39714d3888b46bc4056bd/quenetain.png?w=100&q=100&fm=jpg"
          }
        }
      },
      image: {
        fluid: {
          src: "//images.ctfassets.net/zg5peamxhw22/2Iw2SgaBAye5m6GOZrzGmu/2e59c34afb64aa79206a25ea6d1516d0/blockchain_expert_2_sep.jpg?w=600&q=100&fm=jpg"
        }
      }
    },
    {
      dateEtHeure: "2021-11-23T18:00+01:00",
      description: "Impact de la blockchain sur notre société",
      lienVideo: "https://youtu.be/cs1b7eqb6uE",
      slides: null,
      titre: "Blockchain & Impact",
      speaker: {
        nom: "Adrien HUBERT",
        photo: {
          fluid: {
            src: "//images.ctfassets.net/zg5peamxhw22/5UzIm0i8055qF7UUSJsNfk/337aecfd353837fed25684c8b1efdf23/adrien_hubert.jpg?w=100&q=100&fm=jpg"
          }
        }
      },
      image: {
        fluid: {
          src: "//images.ctfassets.net/zg5peamxhw22/4krJTcrfFya3Jrpxci5MUJ/f6013bc1fd494dc0d1fb7d432a73b8e6/a_hubert_23_nov.png?w=600&q=100&fm=jpg"
        }
      }
    },
    {
      dateEtHeure: "2021-11-18T18:00+01:00",
      description: "La face cachée de la guerre technologique en cours : le contrôle du futur système financier mondial",
      lienVideo: "https://youtu.be/LO_6H4i3eTA",
      slides: null,
      titre: "Blockchain & Geo-strategy",
      speaker: {
        nom: "Marius CAMPOS",
        photo: {
          fluid: {
            src: "//images.ctfassets.net/zg5peamxhw22/unCPKFUfPw4ZCPfZXW0oR/cdbd1eb94aa8e5e8901963ee4ceb8c3b/marius_campos_-_PP-linkedin.jpg?w=100&q=100&fm=jpg"
          }
        }
      },
      image: {
        fluid: {
          src: "//images.ctfassets.net/zg5peamxhw22/4M4xjBc1ieHx6Yyw3RvyHg/e645f544444f494c564da890a3b829a8/m_campos_18_nov.png?w=600&q=100&fm=jpg"
        }
      }
    },
    {
      dateEtHeure: "2021-11-16T18:00+01:00",
      description: "Monetizing the creator economy through social tokens.",
      lienVideo: "https://youtu.be/sPW2Nhyq6hQ",
      slides: null,
      titre: "Monetizing the creator economy through social tokens.",
      speaker: {
        nom: "Ethan PIERSE",
        photo: {
          fluid: {
            src: "//images.ctfassets.net/zg5peamxhw22/2tEmVjnW4cqMdlO09FCTet/d633731ef11557edebf5db68a094f9f6/ethan_pierse.jpg?w=100&q=100&fm=jpg"
          }
        }
      },
      image: {
        fluid: {
          src: "//images.ctfassets.net/zg5peamxhw22/2sQoaYRH2hXryL4e3b0tp6/720958deb83e4ac758c82eac03e49c4c/Blockchain-experts_18mai__11_.png?w=600&q=100&fm=jpg"
        }
      }
    },
    {
      dateEtHeure: "2021-11-09T18:00+01:00",
      description: "101 VECHAIN TECH",
      lienVideo: "https://youtu.be/HY0TBKUer1M",
      slides: null,
      titre: "101 VECHAIN TECH",
      speaker: {
        nom: "Dimitris NEOCLEOUS",
        photo: {
          fluid: {
            src: "//images.ctfassets.net/zg5peamxhw22/63uCS3Pd113NMUzMrefaqz/83222c1587fee52f2225305f785aec46/vechain_9_nov.png?w=100&q=100&fm=jpg"
          }
        }
      },
      image: {
        fluid: {
          src: "//images.ctfassets.net/zg5peamxhw22/5DANyKMt6FTfpdB45vHhL9/ea518e49dabde992636d5a9f549b10d8/vechain_9_nov.png?w=600&q=100&fm=jpg"
        }
      }
    },
    {
      dateEtHeure: "2021-11-02T18:00+02:00",
      description: "Crypto-econnomy : Market debrief with Blockchains-Expert",
      lienVideo: "https://youtu.be/tW0GU1GfwrA",
      slides: null,
      titre: "Crypto-econnomy : Market debrief with Blockchains-Expert",
      speaker: {
        nom: "Stanislas de Quénetain",
        photo: {
          fluid: {
            src: "//images.ctfassets.net/zg5peamxhw22/49ARZy0acBdTmSJ5qWMja0/c7db8eee66d39714d3888b46bc4056bd/quenetain.png?w=100&q=100&fm=jpg"
          }
        }
      },
      image: {
        fluid: {
          src: "//images.ctfassets.net/zg5peamxhw22/2Iw2SgaBAye5m6GOZrzGmu/2e59c34afb64aa79206a25ea6d1516d0/blockchain_expert_2_sep.jpg?w=600&q=100&fm=jpg"
        }
      }
    },
    {
      dateEtHeure: "2021-10-26T18:00+02:00",
      description: "Pitch de 3 startups blockchain - Sandaga, MyTVchain, Stardom - ",
      lienVideo: "https://youtu.be/hjWocvRLwbI?t=6",
      slides: null,
      titre: "Pitch de 3 startups blockchain - Sandaga, MyTVchain, Stardom - ",
      speaker: {
        nom: " Rayann LABIDI - Junior BOUIS - Mathieu PARREAUX",
        photo: {
          fluid: {
            src: "//images.ctfassets.net/zg5peamxhw22/JYxcJE3lyOljpczt2IhRE/7f1fefd4698e490691026e9f72be5bee/startups.jpg?w=100&q=100&fm=jpg"
          }
        }
      },
      image: {
        fluid: {
          src: "//images.ctfassets.net/zg5peamxhw22/3GonFbEVhMhKM7uFNXfkgW/d2eab483cfec326205efe4cb2af3a390/26_oct.JPG?w=600&q=100&fm=jpg"
        }
      }
    },
    {
      dateEtHeure: "2021-10-21T18:00+02:00",
      description: " AMA “Golden Goals” & the democratization of NFTs",
      lienVideo: "https://youtu.be/hG-xVnVUwdE",
      slides: null,
      titre: " AMA “Golden Goals” & the democratization of NFTs",
      speaker: {
        nom: "Emilien Leclercq - Guillaume Martin - Quentin Gibon",
        photo: {
          fluid: {
            src: "//images.ctfassets.net/zg5peamxhw22/251KO2WIx9KUUAy3q9v8Qh/bb1171d3cebba518fa10782812023ad9/profile_goldengoals.JPG?w=100&q=100&fm=jpg"
          }
        }
      },
      image: {
        fluid: {
          src: "//images.ctfassets.net/zg5peamxhw22/2bzjSzOeUwbwmOodZQ3VAk/120d6d853503ca08d6a6ee68278a1d8d/Blockchain-experts_18mai__8_.png?w=600&q=100&fm=jpg"
        }
      }
    },
    {
      dateEtHeure: "2021-10-19T17:00+02:00",
      description: "AMA DOCONCHAIN - Redesigning the gig (#talent #work) economy for the #Web3",
      lienVideo: "https://youtu.be/L_HUOOaH_A0",
      slides: null,
      titre: "AMA DOCONCHAIN - Redesigning the gig (#talent #work) economy for the #Web3",
      speaker: {
        nom: "Frédéric SAURAT",
        photo: {
          fluid: {
            src: "//images.ctfassets.net/zg5peamxhw22/4jKyF729HONSOHmaWBNC3u/637a96938c6a1ba404031d682c84667a/fr_d_ric_saurat.jpg?w=100&q=100&fm=jpg"
          }
        }
      },
      image: {
        fluid: {
          src: "//images.ctfassets.net/zg5peamxhw22/1I4RXrrBWFoJYDnS9ex3t1/a5acdc1d5834d1a0c6cc166666b527ad/fred_saurat.png?w=600&q=100&fm=jpg"
        }
      }
    },
    {
      dateEtHeure: "2021-10-14T18:00+02:00",
      description: "L'ADAN et la dynamique réglementaire de la crypto économie",
      lienVideo: "https://www.youtube.com/watch?v=sGZlvTQxAfc",
      slides: null,
      titre: "L'ADAN et la dynamique réglementaire de la crypto économie",
      speaker: {
        nom: "Faustine Fleuret",
        photo: {
          fluid: {
            src: "//images.ctfassets.net/zg5peamxhw22/5ofANLHN01EEBEbOTrn3R2/ed59b82f54bb55f6a616556c8c91fa85/faustine_fleuret_portrait.JPG?w=100&q=100&fm=jpg"
          }
        }
      },
      image: {
        fluid: {
          src: "//images.ctfassets.net/zg5peamxhw22/2jpupVaO3yBCY8hYkh8zjC/285b2edd221b3ef64cfceaaa2b8fe384/Blockchain-experts_18mai__4_.png?w=600&q=100&fm=jpg"
        }
      }
    },
    {
      dateEtHeure: "2021-10-12T18:00+02:00",
      description: "La Smart-Money dans la crypto-économie avec MySeedAdvisor",
      lienVideo: "https://www.youtube.com/watch?v=7FEr7hGxob0",
      slides: null,
      titre: "Smart-Money dans la crypto-économie",
      speaker: {
        nom: "MySeedAdvisor",
        photo: {
          fluid: {
            src: "//images.ctfassets.net/zg5peamxhw22/Ohkw4ilItFObG3Kn5t0SW/2953d3975018ef5acabc290d947ead21/Blockchain-experts_18mai.jpg?w=100&q=100&fm=jpg"
          }
        }
      },
      image: {
        fluid: {
          src: "//images.ctfassets.net/zg5peamxhw22/2vwQxOu8U301FZxJGAoWcz/f8b48d71d9cd45a0e174eda290b20793/Blockchain-experts_18mai__6_.png?w=600&q=100&fm=jpg"
        }
      }
    },
    {
      dateEtHeure: "2021-10-07T18:00+02:00",
      description: "AMA COINHOUSE with Stéphane IFRAH",
      lienVideo: "https://www.youtube.com/watch?v=9B67hPfCLNc",
      slides: null,
      titre: "AMA COINHOUSE",
      speaker: {
        nom: "Stéphane IFRAH",
        photo: {
          fluid: {
            src: "//images.ctfassets.net/zg5peamxhw22/68EOT8fPvRlHIhIKwYuE7Z/0a4fae3c6a1e3d1e49baec398d0264b9/ifrah-stephaneimg-4783-nb-crop.jpg.png?w=100&q=100&fm=jpg"
          }
        }
      },
      image: {
        fluid: {
          src: "//images.ctfassets.net/zg5peamxhw22/3lCWq5Rx9TT4cFwZQXXbFO/c97d2c85a8ddbf88a0bb6642b79d10d7/ama_coinhouse_7_oct.JPG?w=600&q=100&fm=jpg"
        }
      }
    },
    {
      dateEtHeure: "2021-09-30T18:30+02:00",
      description: "Une révolution de plus en plus visible est-elle en train de s’opérer dans le monde de l'art ?",
      lienVideo: "https://www.youtube.com/watch?v=BpM3aZInY1A",
      slides: null,
      titre: "L'ART et les NFTs",
      speaker: {
        nom: "Emilie Villette, Laetitia Maffei, Anne-Charlotte Cassigneul, Bilal EL ALAMY, Pauline Adam-Kalfon",
        photo: null
      },
      image: {
        fluid: {
          src: "//images.ctfassets.net/zg5peamxhw22/5B2Rj2Um5kcu1fLdCV85Kv/d9bf5d9facb72f215f6f6cbf18c71505/art_et_nft.png?w=600&q=100&fm=jpg"
        }
      }
    },
    {
      dateEtHeure: "2021-09-14T18:00+02:00",
      description: "Retreeb, la solution innovante de paiement responsable et solidaire et dont l’ICO est en cours",
      lienVideo: "https://www.youtube.com/watch?v=YbRZ26DJnzs",
      slides: {
        "file": {
          "url": "//assets.ctfassets.net/zg5peamxhw22/2VKwJg9epiifBsGgDw2cpK/21d46c452826e176cb4ca09e2d75f01c/retreeb-white-paper.pdf"
        }
      },
      titre: "AMA \"RETREEB\" & ICO",
      speaker: {
        nom: "Jérémi Lepetit et Stéphanie Flacher ",
        photo: {
          fluid: {
            src: "//images.ctfassets.net/zg5peamxhw22/39wuZXsCC6CGOn9RQD0F1T/c55b15a52b4840b25d9a724570e53c1d/jeremi_leptit_et_stephanie_flacher.JPG?w=100&q=100&fm=jpg"
          }
        }
      },
      image: {
        fluid: {
          src: "//images.ctfassets.net/zg5peamxhw22/2xqQwZ0KIqXS8U3aqpHrjI/7517123345836d8cde2c0198e283eceb/14_sep_2021.JPG?w=600&q=100&fm=jpg"
        }
      }
    },
    {
      dateEtHeure: "2021-09-09T18:00+02:00",
      description: "AMA Polkadot - un des projets les plus suivis de la cryptosphère",
      lienVideo: "https://www.youtube.com/watch?v=6e5COSa5yJk",
      slides: {
        "file": {
          "url": "//assets.ctfassets.net/zg5peamxhw22/Q1hcyJiVpAxqEUGqBaQEB/13d3c938ed6e59875ecf57eb8bddfac9/polkadot_meetup_hec.pdf"
        }
      },
      titre: "AMA POLKADOT",
      speaker: {
        nom: "David SCHMITZ",
        photo: {
          fluid: {
            src: "//images.ctfassets.net/zg5peamxhw22/60SsKdHCzsPtvHTjz1RP7i/df0f05ece51ad6f4cf985497e2a74711/DavidSchmitz-300x300.png?w=100&q=100&fm=jpg"
          }
        }
      },
      image: {
        fluid: {
          src: "//images.ctfassets.net/zg5peamxhw22/4EQ9lDmSjHReMEvzCiteXU/45727fd90f1d23f567a595b214fe911e/polkadot_-_9_septembre.JPG?w=600&q=100&fm=jpg"
        }
      }
    },
    {
      dateEtHeure: "2021-09-02T18:00+02:00",
      description: "Market debrief - 22 sep 2021",
      lienVideo: "https://www.youtube.com/watch?v=sCdBdBjU2xs",
      slides: {
        "file": {
          "url": "//downloads.ctfassets.net/zg5peamxhw22/4d9GwTduESQ8zCHhLtWLI5/d51df33c33f29dc3f39a3773faaa21e4/Presentation_290821.pdf"
        }
      },
      titre: "Crypto-economy",
      speaker: {
        nom: "Stanislas de Quénetain",
        photo: {
          fluid: {
            src: "//images.ctfassets.net/zg5peamxhw22/49ARZy0acBdTmSJ5qWMja0/c7db8eee66d39714d3888b46bc4056bd/quenetain.png?w=100&q=100&fm=jpg"
          }
        }
      },
      image: {
        fluid: {
          src: "//images.ctfassets.net/zg5peamxhw22/2Iw2SgaBAye5m6GOZrzGmu/2e59c34afb64aa79206a25ea6d1516d0/blockchain_expert_2_sep.jpg?w=600&q=100&fm=jpg"
        }
      }
    },
    {
      dateEtHeure: "2021-06-17T00:00+02:00",
      description: "Tour d'horizon des NFTs (Non Fungible Tokens)",
      lienVideo: "https://youtu.be/nCNbS1nYMrk",
      slides: {
        "file": {
          "url": "//downloads.ctfassets.net/zg5peamxhw22/nwBaUBB5vJ0mlUsNoJAEh/15897e410c020218323aae9d4ef4786d/NFT_HEC.pptx"
        }
      },
      titre: "NFT - Tour d'horizon",
      speaker: {
        nom: "Nicolas CANTU - Nicolas POUARD -  Damien PATUREAUX - Florent THURIN",
        photo: null
      },
      image: {
        fluid: {
          src: "//images.ctfassets.net/zg5peamxhw22/4RXb7nR9Th59fgjZfa7hBX/071327729708e8dc7da4a39782f5d0a1/NFT_image.JPG?w=600&q=100&fm=jpg"
        }
      }
    },
    {
      dateEtHeure: "2021-06-10T00:00+02:00",
      description: "La finance décentralisée (DEFI) expliquée par Adrien HUBERT",
      lienVideo: "https://www.youtube.com/watch?v=3dbFBWyz8DI&t=2s",
      slides: null,
      titre: "Dencentralized Finance (DEFI)",
      speaker: {
        nom: "Adrien HUBERT",
        photo: {
          fluid: {
            src: "//images.ctfassets.net/zg5peamxhw22/5UzIm0i8055qF7UUSJsNfk/337aecfd353837fed25684c8b1efdf23/adrien_hubert.jpg?w=100&q=100&fm=jpg"
          }
        }
      },
      image: {
        fluid: {
          src: "//images.ctfassets.net/zg5peamxhw22/497CdlWueLamLUhpveaddD/d8933afb00e5e19679aa4d6b17a4329a/Defi_-_10_juin_2021.png?w=600&q=100&fm=jpg"
        }
      }
    },
    {
      dateEtHeure: "2021-06-08T00:00+02:00",
      description: "Delmonicos - Evidenz - Cryptio",
      lienVideo: "https://www.youtube.com/watch?v=fngFeAVy_Js",
      slides: null,
      titre: "PITCH 3 Startups",
      speaker: {
        nom: "Vincent LANGARD - Franck LEGARDEUR - Antoine SCALIA",
        photo: null
      },
      image: {
        fluid: {
          src: "//images.ctfassets.net/zg5peamxhw22/5l47aaeNnuXwC8S0c588ta/263e4236ac1afc7c585b53a1b453d0f1/image_pitch_session.JPG?w=600&q=100&fm=jpg"
        }
      }
    },
    {
      dateEtHeure: "2021-06-03T18:00+02:00",
      description: "🎯FOCUS sur le protocole Tezos : une plateforme open source d’actifs et d’applications. Elle est soutenue par une communauté mondiale de validateurs, chercheurs et développeurs. 3 axes de proposition de valeur:  #sécurité, #évolutif et #durable",
      lienVideo: "https://www.youtube.com/watch?v=hMhTNppVpTk",
      slides: {
        "file": {
          "url": "//assets.ctfassets.net/zg5peamxhw22/20bingfbdholBY1ETesaBr/35c7967fb22ff325fe0e2ff9bf500e87/Tezos_Overview.pdf"
        }
      },
      titre: "AMA Tezos",
      speaker: {
        nom: "Thibaut Chesse",
        photo: {
          fluid: {
            src: "//images.ctfassets.net/zg5peamxhw22/3g0wYE61PTD2UJ7ATkfmV7/c8630296456d7dc36f881ac80b7272b0/tez.png?w=100&q=100&fm=jpg"
          }
        }
      },
      image: {
        fluid: {
          src: "//images.ctfassets.net/zg5peamxhw22/4VdTtqEnGEVS6hnT4175Ff/d6191bc74d01467b419dd21ca0a82b3b/Tezos.jpg?w=600&q=100&fm=jpg"
        }
      }
    },
    {
      dateEtHeure: "2021-06-01T18:00+02:00",
      description: "RDV 1er juin de 18h00 à 19h00 : \"Le point sur le #marché des #cryptoactifs” par #Blockchains-Expert avec Stanislas de Quénetain",
      lienVideo: "https://www.youtube.com/watch?v=1NnQYhV4TI4",
      slides: null,
      titre: "Tour de table sur la cryptoéconomie",
      speaker: {
        nom: "Stanislas de Quénetain",
        photo: {
          fluid: {
            src: "//images.ctfassets.net/zg5peamxhw22/49ARZy0acBdTmSJ5qWMja0/c7db8eee66d39714d3888b46bc4056bd/quenetain.png?w=100&q=100&fm=jpg"
          }
        }
      },
      image: {
        fluid: {
          src: "//images.ctfassets.net/zg5peamxhw22/23bScZ9nwNhi7Bkr0Aqnlw/555f04441d83a96c0c7ffe19dd02d3ac/quenetain.jpg?w=600&q=100&fm=jpg"
        }
      }
    },
    {
      dateEtHeure: "2021-05-27T18:00+02:00",
      description: "🎯Suite à la conférence sur la Tokenisation de mardi 25 mai, nous vous invitons à découvrir ce jeudi 27 mai un cas d’école de \"Token economy\" appliqué à  l'usage durable de l'Espace.",
      lienVideo: "https://www.youtube.com/watch?v=vT-asvNUkTs",
      slides: {
        "file": {
          "url": "//assets.ctfassets.net/zg5peamxhw22/yeNMG7vpfehkehzlKcCeI/9478105218d3474c93c2c3e850bad463/Syst__me_d-utilisation_durable_des_orbites_-_Rapport_final_v0.6__2_.pdf"
        }
      },
      titre: "L'usage durable de l'espace grâce à la token economy",
      speaker: {
        nom: "Nicolas Cantu",
        photo: {
          fluid: {
            src: "//images.ctfassets.net/zg5peamxhw22/1oYUFqgq3OXidg1AgC3jRi/44968dbb9ba26a4e930496525a07242e/cantu.png?w=100&q=100&fm=jpg"
          }
        }
      },
      image: {
        fluid: {
          src: "//images.ctfassets.net/zg5peamxhw22/50HvL1UjSyKh8BooSmGLTd/d71a03b53ac10f5d1b0f6a079c56be92/espace.jpg?w=600&q=100&fm=jpg"
        }
      }
    },
    {
      dateEtHeure: "2021-05-25T18:00+02:00",
      description: "🎯 Quel est ce processus appelé “tokenisation”? Qu’est ce qu’un #token? Comment rendre digital un bien du monde réel? Quels sont ces impacts sur notre société, notre économie? La #tokenisation se développe à grand pas! et sera le focus de notre conférence.",
      lienVideo: "https://www.youtube.com/watch?v=9BaBZQGV8jo",
      slides: null,
      titre: "La Tokenisation",
      speaker: {
        nom: "Pierre Paperon",
        photo: {
          fluid: {
            src: "//images.ctfassets.net/zg5peamxhw22/1Ljd864hRnUIU0wgMUWhNC/3b204a9b6a6b1124019cbe363c81a982/Pierre-Paperon-AQUAE-PANORAMA-2019__Photo-Faust-Favart_200__1_.jpg?w=100&q=100&fm=jpg"
          }
        }
      },
      image: {
        fluid: {
          src: "//images.ctfassets.net/zg5peamxhw22/48IAlbRFqFy9XuSYBDYib3/48abd5f5de5c13e9bc8a1d8b69d01434/Tokenisation.jpeg?w=600&q=100&fm=jpg"
        }
      }
    },
    {
      dateEtHeure: "2021-05-24T18:00+02:00",
      description: "Let's discover the #Oracles and its #strategic use cases in real life with #Smartcontract and #decentralization. #Oracle is any device or entity that connects a deterministic blockchain with off-chain data.",
      lienVideo: "https://www.youtube.com/watch?v=YBM-OZrAiEg",
      slides: {
        "file": {
          "url": "//assets.ctfassets.net/zg5peamxhw22/7r8a0QYJ38itl6yuGE7FH9/37999d58cbb601767e7b2ecaae023a60/CHAINLINK.pdf"
        }
      },
      titre: "The Oracles & AMA",
      speaker: {
        nom: "Johann Eid",
        photo: {
          fluid: {
            src: "//images.ctfassets.net/zg5peamxhw22/1I4kkBGAJvuAldRMnJPpMM/43176e7c66c2e7f2ea93569d3619ccaf/chainlink_johann_eid.png?w=100&q=100&fm=jpg"
          }
        }
      },
      image: {
        fluid: {
          src: "//images.ctfassets.net/zg5peamxhw22/35lJNBouGXXXzOxLE9zi6F/d49bf111b425bf27bafa2067d1dbb4c0/Oracles.jpg?w=600&q=100&fm=jpg"
        }
      }
    },
    {
      dateEtHeure: "2021-05-18T18:00+02:00",
      description: "🎯 Focus sur les analyses macro et fondamentales de la #crypto#économie et notamment des derniers épisodes #épiques.",
      lienVideo: "https://www.youtube.com/watch?v=nCATZUIrY28",
      slides: {
        "file": {
          "url": "//assets.ctfassets.net/zg5peamxhw22/SkeZhVQ5pJA57wlQpgwF3/15b0311d0a2028abd8f700efda068108/CryptoEconomie-_Les_cycles_des_crypto-actifs_-_180521_2.pptx"
        }
      },
      titre: "Cryptoéconomie : les cycles des crypto-actifs",
      speaker: {
        nom: "Stanislas de Quénetain",
        photo: {
          fluid: {
            src: "//images.ctfassets.net/zg5peamxhw22/49ARZy0acBdTmSJ5qWMja0/c7db8eee66d39714d3888b46bc4056bd/quenetain.png?w=100&q=100&fm=jpg"
          }
        }
      },
      image: {
        fluid: {
          src: "//images.ctfassets.net/zg5peamxhw22/3XkUD8iB8ZWJcxJa2AJjGe/4271065dbf0a4e3cd1b03e9709485bb7/cycles_crypto.jpg?w=600&q=100&fm=jpg"
        }
      }
    },
    {
      dateEtHeure: "2021-05-13T18:00+02:00",
      description: "#AMA (Ask Me Anything) Hedera Hashgraph par Greg Scullard, Lead Developer Advocate, sur ZOOM de Blockchain@HEC Paris 🎯 Focus sur les projets concrets et les perspectives",
      lienVideo: "https://www.youtube.com/watch?v=TvOHRtJKfNE",
      slides: {
        "file": {
          "url": "//downloads.ctfassets.net/zg5peamxhw22/6W5L3BDSuqKXXMsZntnZjV/8bbc358a3a270171625ba94f77a96e0b/Hedera__Corporate_Presentation_May2021-French.pptx"
        }
      },
      titre: "AMA Hedera Hashgraph",
      speaker: {
        nom: "Greg Scullard",
        photo: {
          fluid: {
            src: "//images.ctfassets.net/zg5peamxhw22/4O9cZ9teAl2Rdc5Ey1VAD0/34932e90b50d6cdca04cbee72c220ed1/hashgraph.png?w=100&q=100&fm=jpg"
          }
        }
      },
      image: {
        fluid: {
          src: "//images.ctfassets.net/zg5peamxhw22/70tRyOCsM8Vv9PQm2svRid/91e59673087d371923d38f5a10dee59e/Hedera_miniature_vid__o.JPG?w=600&q=100&fm=jpg"
        }
      }
    },
    {
      dateEtHeure: "2021-05-06T18:00+02:00",
      description: "🎯 Focus sur les problématiques de la BITCOIN liées à l'Energie.",
      lienVideo: "https://www.youtube.com/watch?v=HYKG1UfUrpY",
      slides: {
        "file": {
          "url": "//assets.ctfassets.net/zg5peamxhw22/2blEkgx38i4W4qdExnoXwz/50297ab43ad81041e0b06f11031256fe/Pierre_Noizat_pr__sentation_-_Bitcoin_et_Energie_-_Webinar_HEC_210506.pdf"
        }
      },
      titre: "Bitcoin & Energie",
      speaker: {
        nom: "Pierre Noizat",
        photo: {
          fluid: {
            src: "//images.ctfassets.net/zg5peamxhw22/7yS2N4pE261y1zmTUq1PMH/656decc7b7fee8f574ca0abcfae8bdba/noizat.jpg?w=100&q=100&fm=jpg"
          }
        }
      },
      image: {
        fluid: {
          src: "//images.ctfassets.net/zg5peamxhw22/3wib2WIWlJNY8dOkQuAPCl/d3dae0d827d2f271d3df8a039fcfa098/bitcoin___energie.jpg?w=600&q=100&fm=jpg"
        }
      }
    },
    {
      dateEtHeure: "2021-04-29T18:00+02:00",
      description: "\"AMA (Ask Me About) KeeeX et #Blockchain Layer 2\" par Laurent HENOCQUE : Focus sur les solutions Blockchain de couche 2(« Layer2 ») ou dite « Off-Chain» et des derniers cas d'usages concrets des solutions Blockchain de la startups KEEEX. ",
      lienVideo: "https://www.youtube.com/watch?v=q7CZbkcqwQ4",
      slides: {
        "file": {
          "url": "//assets.ctfassets.net/zg5peamxhw22/67TxQ3dK4a9WCltgugOnwV/d75770ec91b0da2fea00b5ae0420b19c/Pr__sentation_2Layer_HEC_small110_-keeexed--2021-04-29T16_42_15_02_00-xepil-nocek-_____1_.pdf"
        }
      },
      titre: "La blockchain Layer 2",
      speaker: {
        nom: "Laurent Henocque",
        photo: {
          fluid: {
            src: "//images.ctfassets.net/zg5peamxhw22/3QmRPkQVWEodEm6KwDcMBn/5b77d0e1ec67064c71110140382669df/zebox-portrait-330x330-startup-ceo-keeex-henocque-laurent.jpg?w=100&q=100&fm=jpg"
          }
        }
      },
      image: {
        fluid: {
          src: "//images.ctfassets.net/zg5peamxhw22/7DdKxSW6xmGibdBq2koqHh/550b3d2077b5bc7996a2526ddd369895/Keeex.jpg?w=600&q=100&fm=jpg"
        }
      }
    },
    {
      dateEtHeure: "2021-04-27T18:30+02:00",
      description: "\"La blockchain en 2021, où en est-on?\" présentée par Pierre Paperon. Parmi les sujets abordés:  évolutions majeures, enjeux technologiques et géo-stratégiques ,  les #NFT, la #DEFI et autres tendances émergentes ",
      lienVideo: "https://www.youtube.com/watch?v=ezU0q32D7Fo",
      slides: {
        "file": {
          "url": "//assets.ctfassets.net/zg5peamxhw22/37xkapRopxh6QHAo31jvxn/8c397f3e3a49eb772120555cfade3567/Partage_HEC_du_27_avril_2021__1_.pdf"
        }
      },
      titre: "La blockchain en 2021 ?",
      speaker: {
        nom: "Pierre Paperon",
        photo: {
          fluid: {
            src: "//images.ctfassets.net/zg5peamxhw22/1Ljd864hRnUIU0wgMUWhNC/3b204a9b6a6b1124019cbe363c81a982/Pierre-Paperon-AQUAE-PANORAMA-2019__Photo-Faust-Favart_200__1_.jpg?w=100&q=100&fm=jpg"
          }
        }
      },
      image: {
        fluid: {
          src: "//images.ctfassets.net/zg5peamxhw22/2vyOy07okDPZE8QrtiXDnl/19558e11c3f05e2f76e9e402de2db645/vision_-_Blockchain_HEC__1_.JPG?w=600&q=100&fm=jpg"
        }
      }
    },
    {
      dateEtHeure: "2021-04-22T18:00+02:00",
      description: "Histoire du bitcoin par Jean-Jacques Quisquater : cryptographe belge, professeur à l'université catholique de Louvain en Belgique, co-inventeur du schéma d'identification Guillou-Quisquater.",
      lienVideo: "https://www.youtube.com/watch?v=xLBWTealVXw",
      slides: {
        "file": {
          "url": "//assets.ctfassets.net/zg5peamxhw22/1YAY54xbp0twylU6LB2K8q/22af777477be7d26a6b82ce8811f2f88/test_pdf.pdf"
        }
      },
      titre: "L’histoire de la Bitcoin",
      speaker: {
        nom: "Jean-Jacques Quisquater",
        photo: {
          fluid: {
            src: "//images.ctfassets.net/zg5peamxhw22/4ylCMBpxGaRbuQ8QlLJBhX/46507e02de78a0207f52c291294d9645/Jean-Jacques-Quisquater.jpg?w=100&q=100&fm=jpg"
          }
        }
      },
      image: {
        fluid: {
          src: "//images.ctfassets.net/zg5peamxhw22/6xGmJlNoeqRYY2qb6qv12x/e480fc7a1ccf0e88212859bd8c4c3490/bitcoin-blackrock-blockchain.jpg?w=600&q=100&fm=jpg"
        }
      }
    }
  ]
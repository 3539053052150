import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

import Home from "./pages/Home/Home.jsx";
import Organisation from "./pages/Organisation/Organisation";
import Team from "./pages/Team/Team";
import Incubator from "./pages/Incubator/Incubator";
import Events from "./pages/Events/Events";
import Page404 from "./pages/Page404/Page404";

import Header from "./Header";
import Footer from "./Footer";

import { handleAppears } from "./index.jsx";

import "./App.css";

export default function App() {
  window.addEventListener("scroll", () => {
    handleAppears();
  });
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/team" element={<Team />} />
        <Route path="/events" element={<Events />} />
        <Route path="/organisation" element={<Organisation />} />
        <Route path="/incubator" element={<Incubator />} />
        <Route path="*" element={<Page404 />} />
      </Routes>
      <Footer />
    </Router>
  );
}
